const faqSchemaId = 'faq-schema-gmp';

// Based on https://developers.google.com/search/docs/advanced/structured-data/faqpage?hl=de
function generateFaqSchema(faqData) {
    const schemaObject = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        'mainEntity': []
    };

    faqData.forEach(faqEntry => {
        schemaObject.mainEntity.push({
            '@type': 'Question',
            'name': faqEntry.question,
            'acceptedAnswer': {
                '@type': 'Answer',
                'text': faqEntry.answer,
            }
        })
    })

    return schemaObject;
}

function getFaqSchemaScriptTag(faqType) {
    return document.getElementById(`${faqSchemaId}-${faqType}`)
}

export function createFaqSchemaScript(faqType, faqData) {
    const existingSchemaScript = getFaqSchemaScriptTag(faqType);
    // Script is added once during prerendering
    if (existingSchemaScript) {
        return;
    }

    const schemaScript = document.createElement('script');
    schemaScript.type = 'application/ld+json';
    schemaScript.id = `${faqSchemaId}-${faqType}`;
    schemaScript.text = JSON.stringify(generateFaqSchema(faqData));
    document.head.append(schemaScript);
}

export function destroyFaqSchemaScript(faqType) {
    const existingSchemaScript = getFaqSchemaScriptTag(faqType);
    if (existingSchemaScript) {
        existingSchemaScript.remove();
    }
}