<template>
    <div class="nmk-teaser-wrapper pt-3 pt-md-7">
        <!--<picture class="">-->
            <!--<source srcset="@/assets/images/advantages-front-small.png, @/assets/images/advantages-front-small.png 2x">-->
        <div class="front-wrapper bg-secondary">
            <img src="@/assets/images/NMK-teaser-front.png" class="front-image" alt="Verkaufen Sie Ihre gebrauchte Maschine!">
        <!--</picture>-->
        <div class="container pb-md-7">
            <div class="row">
                <!--<img class="img-fluid d-md-none" src="@/assets/images/headervisual-1200-1x.jpg" alt="">-->
                <picture class="d-md-none full-width">
                    <source srcset="@/assets/images/NMK-teaser-back-768_1x.jpg, @/assets/images/NMK-teaser-back-768_1x.jpg 2x">

                    <img src="@/assets/images/NMK-teaser-back-768_1x.jpg" class="img-fluid full-width" alt="Verkaufen Sie Ihre gebrauchte Maschine!">
                </picture>
                <div class="text-white advantages-overlay col-12 col-md-10 col-lg-12 mr-auto pt-5 pb-5 pb-md-7">
                    <h3 class="pb-4 pb-md-5">Cat Baumaschinen <br class="d-none d-md-inline d-lg-none"/>konfigurieren</h3>
                    <div class="mb-2 font-weight-light">Ihr&nbsp;neuer&nbsp;Cat&nbsp;– Was&nbsp;er&nbsp;kann, was&nbsp;er&nbsp;kostet</div>

                    <a id="gbm-eval-body" class="btn btn-warning text-uppercase font-weight-bold px-5 mt-3" href="https://www.konfigurator.zeppelin-cat.de/" target="_blank">Zum Konfigurator</a>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'external-pages-banner',
    }
</script>

<style lang="scss">
    .nmk-teaser-wrapper {
        overflow-x: hidden;
        max-width: 100vw;

        .front-wrapper {
            position: relative;
            overflow-y: visible;

            @include media-breakpoint-up(md) {
                background-image: linear-gradient(to right, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 80%), url('../assets/images/NMK-teaser-back-1200_2x.jpg');
                //background-image: url('../assets/images/NMK-teaser-back-1200_2x.jpg');
                background-size: cover;
                background-position: right;
            }
        }

        .front-image {
            position: absolute;
            right: 2vw;
            height: 116%;
            bottom: 0;

            @include media-breakpoint-down(sm) {
                display: none;
            }

            @include media-breakpoint-up(md) {
                right: 0;
            }
        }

        .row {
            position: relative;
        }
    }
</style>
