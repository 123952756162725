<template>
    <div class="error-wrapper pt-0 pt-sm-7">
        <div class="container py-5">
            <div class="row">
                <div class="col-12 col-md-6">
                    <h2>Tief gebuddelt,<br>aber doch nichts gefunden!</h2>
                    <p class="font-weight-light">Es ist ein Fehler aufgetreten.</p>
                    <p class="my-5">
                        Hier geht es <router-link class="no-color" :to="{name: 'home'}">zurück zur Startseite</router-link>.
                    </p>
                </div>
                <div class="col-12 col-sm-6 mx-auto">
                    <img src="@/assets/images/illustration-404.svg" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'error'
    }
</script>
