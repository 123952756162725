export const contactData = {
    last_name_de: '',
    first_name_de: '',
    filePicUrl: '',
    street: '',
    zipCode: '',
    city: '',
    email: '',
    mobile: '',
    phone: '',
    fax: ''
};