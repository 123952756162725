export default [
    {
        question: 'Wie funktioniert die Zeppelin Baggerbörse?',
        answer: '<p>Als erstes führen Sie unsere kostenlose Online-Bewertung durch. Dafür brauchen wir von Ihnen Basisdaten zu Ihrer Baumaschine, wie zum Beispiel Hersteller, Kategorie, Baujahr und Betriebsstundenstand. Im Anschluss erhalten Sie per E-Mail umgehend eine erste Schätzung. Diese dient Ihnen als Orientierung für den ungefähren Wert Ihrer Baumaschine.</p><p>Final erfolgt eine Bewertung vor Ort der Baumaschine durch unsere Gebrauchtmaschinen-Experten mit langjähriger Berufserfahrung. Der Ankauf erfolgt dann durch eine Zeppelin Baumaschinen Niederlassung in der Nähe. </p>'
    },
    {
        question: 'Wo finde ich die Zeppelin Baumaschinen Niederlassungen?',
        answer: '<p>Wir betreiben 35 Niederlassungen in Deutschland. Eine Übersicht aller Standorte finden Sie <a href="https://www.zeppelin-cat.de/locations/#/51.30676270549856/10.225893999999988/6/DE//" target="_blank" class="no-color">hier</a>.</p>'
    },
    {
        question: 'Wie wird die Online-Bewertung meiner Baumaschine auf der Website durchgeführt?',
        answer: '<p>Geben Sie Ihre Maschinendaten ganz einfach in das Formular ein. Sie erhalten innerhalb von Minuten kostenlos eine unverbindliche Erstbewertung per E-Mail.</p>'
    },
    {
        question: 'Wie erfahre ich, was meine Baumaschine noch wert ist?',
        answer: '<p>Die Wertermittlung ist dank der Baggerbörse für Sie besonders einfach, schnell und nicht mit Gebühren verbunden. Die Erstbewertung Ihrer Maschine erfolgt in dem Sie die Basisdaten Ihrer Baumaschine eintragen. Sie erhalten umgehend eine erste Schätzung der Maschine.</p>'
    },
    {
        question: 'Wie kann ich meine Baumaschine nach der Online-Bewertung verkaufen?',
        answer: '<p>Nachdem Sie eine Schätzung erhalten haben, erfolgt eine finale Bewertung der Baumaschine von einem unserer Gebrauchtmaschinen-Experten vor Ort. Im Anschluss erhalten Sie einen endgültigen Verkaufspreis von uns. Zum Schluss wird die Maschine von einer Zeppelin Niederlassung angekauft.</p>'
    },
    {
        question: 'Muss meine Baumaschine von Zeppelin CAT sein?',
        answer: '<p>Nein, der Ankauf erfolgt auch unabhängig vom Hersteller.</p>'
    },
    {
        question: 'Warum sollte ich meine Baumaschine über die Baggerbörse verkaufen?',
        answer: '<p>Der Ankauf erfolgt durch eine Zeppelin Niederlassung mit Gebrauchtmaschinen-Experten, welche langjährige Erfahrung in der Branche haben und Sie persönlich beraten. Die Abwicklung erfolgt zudem professionell, einfach und seriös.</p>'
    },
    {
        question: 'Wozu werden meine E-Mail-Adresse und Telefonnummer benötigt?',
        answer: '<p>Wir benötigen Ihre E-Mail-Adresse, um Ihnen das Ergebnis der durchgeführten Online-Bewertung zuzuschicken. Sollten Sie eine telefonische Kontaktaufnahme und Beratung durch unsere Gebrauchtmaschinen-Experten wünschen, benötigen wir zusätzlich Ihre Telefonnummer.</p>'
    },
    {
        question: 'Fallen beim Verkauf meiner Baumaschine Gebühren an?',
        answer: '<p>Der gesamte Bewertungsprozess bis hin zum Ankauf der Baumaschine, durch die Baggerbörse ist für Sie völlig kostenlos.</p>'
    },
    {
        question: 'Muss ich Gebühren bezahlen, wenn ich meine Baumaschine nicht verkaufen möchte?',
        answer: '<p>Die Baggerbörse ist für Sie ein kostenloser Service, auch wenn Sie Ihre Maschine nicht an uns verkaufen.</p>'
    },
    {
        question: 'Meine Baumaschine ist nicht mehr verkehrstüchtig. Kann ich sie dennoch bei der Baggerbörse verkaufen?',
        answer: '<p>Selbstverständlich, dieses Kriterium wird in die Bewertung mit einfließen. Maschinen können von uns auch repariert und instandgesetzt werden.</p>'
    },
    {
        question: 'Kann ich die Daten im Nachhinein bearbeiten?',
        answer: '<p>Bei einer Fehleingabe starten Sie einfach eine neue Bewertung.</p>'
    },
    {
        question: 'Ich habe vor einiger Zeit eine Online-Bewertung meiner Baumaschine durchgeführt. Ist dieser Wert noch gültig?',
        answer: '<p>Die Zeit bringt laufend Veränderungen mit sich, so dass man immer auf eine tagesaktuelle Bewertung blicken sollte. Sofern wir die Maschine besichtigt und Ihnen bereits einen Preis genannt haben, gilt dieser gemäß individueller Vereinbarung.</p>'
    },
    {
        question: 'Erhalte ich den endgültigen Verkaufspreis, den ich per E-Mail bekommen habe?',
        answer: '<p>Dies kann tatsächlich so sein, aber es bedarf immer einer Besichtigung und finalen Einschätzung durch unsere Experten. Verschleißbilder plus/minus / Schäden an Maschinen können natürlich zu einer Abweichung des theoretisch ermittelten Wertes führen.</p>'
    },
    {
        question: 'Was ist der Unterschied zwischen der Schätzung und dem endgültigen Verkaufspreis?',
        answer: '<p>Im ersten Schritt teilen Sie uns die Basisdaten wie Hersteller, Kategorie, Baujahr und Betriebsstundenstand der Maschine mit. Danach erhalten Sie eine erste Schätzung.</p><p>Im zweiten Schritt ergänzen Sie Ihre Angaben und sprechen mit einem unserer langjährigen Gebrauchtmaschinen-Experten, welcher die Baumaschine nochmal vor Ort bewertet.</p>'
    },
    {
        question: 'Welche Informationen sind für die Ermittlung des endgültigen Verkaufspreises nötig?',
        answer: '<p>Der Rückgabezeitpunkt, die bis dahin zusätzlich gefahrenen Betriebsstunden, Details zur Ausstattung und den Anbaugeräten sowie der tatsächliche Zustand der Baumaschine sind zu besprechen.</p>'
    },
    {
        question: 'Wie lange dauert es, bis ich den endgültigen Verkaufspreis erfahre?',
        answer: '<p>Sie erhalten den endgültigen Verkaufspreis nach Besichtigung durch unsere Gebrauchtmaschinen-Experten vor Ort oder spätestens nach einem Tag.</p>'
    },
    {
        question: 'Wie bezahlt ihr den Verkaufspreis für meine Baumaschine und ist dabei ein Barankauf möglich?',
        answer: '<p>Wir bezahlen grundsätzlich durch Banküberweisung und machen keine Bargeschäfte. Hierfür benötigen wir von Ihnen, nach erfolgtem Vertragsabschluss, eine den gesetzlichen Vorgaben entsprechende Rechnung.</p>'
    },
    {
        question: 'Ist es möglich, dass ihr meine Baumaschine abholt?',
        answer: '<p>Die Maschine kann von Ihnen zu einer unserer Niederlassungen angeliefert oder von uns durch einen Spediteur -nach Vereinbarung- abgeholt werden.</p>'
    },
    {
        question: 'Wie lange dauert die Bewertung und der Ankauf meiner Baumaschine?',
        answer: '<p>Erfahrungsgemäß kann dies innerhalb von 1 – 2 Wochen abgewickelt werden.</p>'
    }
]