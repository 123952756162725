<template>
    <div class="gbm-header-wrapper bg-white">
        <div class="container">
            <div class="row no-gutters align-items-center">
                <a class="header-logo" role="button" href="https://www.zeppelin-cat.de/" target="_blank">
                    <img alt="Bagerboerse logo" src="../../assets/images/zeppelin-logo.svg" />
                </a>
                <div class="header-text"><strong>Baggerboerse<span>de</span></strong></div>
                <button
                        id="gbm-eval-header"
                        class="btn border-secondary bg-white font-mono font-weight-bold font-size-smallest d-none d-md-block ml-auto py-1 px-3"
                        v-if="isOnHome"
                        @click="scrollToTop">Jetzt bewerten lassen</button>
                <button
                        id="gbm-eval-mobile"
                        class="btn bg-primary button-back-to-top font-weight-bold d-md-none ml-auto py-1 px-3"
                        v-if="isOnHome && showSticky"
                        @click="scrollToTop">Jetzt bewerten lassen</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'gbm-header',
        data() {
          return {
              isScrolled: false
          }
        },
        methods: {
            scrollToTop() {
                this.$store.dispatch('scrollToForm');
            }
        },
        computed: {
            isOnHome() {
                return this.$route.name === 'home';
            },
            showSticky() {
                return !this.$store.state.machineformPosition.visible && window.scrollY >= 300;
            }
        }
    }
</script>

<style lang="scss">
    $header-height: 70px;
    $inner-header-height: 40px;

    .gbm-header-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1020;
        box-shadow: 0 20px 40px rgba($gray-700, 0.15);

        .row {
            height: $header-height;

            .header-logo {
                img {
                    width: auto;
                    height: $inner-header-height;
                    /* so IE doesn't get confused by width:auto */
                    min-width: 158px;
                }
            }

            button {
                height: $inner-header-height;
                text-transform: uppercase;

                &.button-back-to-top{
                    position: fixed;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: $input-height;
                    border-radius: 0;
                    z-index: $zindex-fixed;

                    &.hide-button {
                        display: none;
                    }
                }
            }
        }

        .header-text {
            text-transform: uppercase;
            font-size: $font-size-base;
            /* must be as much as the height of the "C" in "CAT" */
            margin-left: 18px;

            span {
                font-size: $font-size-base / 1.7;
                color: $primary;
            }
        }
    }
</style>