<template>
    <div class="steps-wrapper">
        <div class="pt-4 pb-3" :class="{'bg-light steps-gradient pb-md-0': afterForm}">
            <div class="container">
                <h2 class="mb-4 mt-5" v-if="!afterForm">Wie funktioniert der Verkauf Ihrer Gebraucht&shy;maschine</h2>
                <h5 class="mb-4 pt-4" v-else>Wie sind die nächsten Schritte</h5>
                <div class="row">
                    <div class="step col-12 col-md-4 text-center text-md-left"
                         :class="{'arrow-indicator': index === 0 && afterForm}"
                         v-for="(step, index) in steps" :key="index">

                        <img v-if="!afterForm" :src="step.imgSrcBefore" :alt="step.before" />
                        <img v-else :src="step.imgSrcAfter" :alt="step.after" />

                        <div class="" v-if="!afterForm">{{ step.before }}</div>
                        <div class="pb-4 d-none d-md-inline-block" v-if="afterForm">{{ step.after }}</div>
                        <div class="position-relative pb-4 d-md-none"
                             :class="{'arrow-indicator': index === 0}" v-if="afterForm">{{ step.after }}</div>

                        <steps-contact class="row d-md-none bg-white"
                                       v-if="afterForm && index === 0">
                        </steps-contact>
                    </div>
                </div>
            </div>
        </div>
        <steps-contact class="d-none d-md-block"
                       v-if="afterForm">
        </steps-contact>
    </div>
</template>

<script>
    import step101 from '../assets/images/illustration-step-1-01.svg';
    import step102 from '../assets/images/illustration-step-1-02.svg';
    import step103 from '../assets/images/illustration-step-1-03.svg';
    import step301 from '../assets/images/illustration-step-3-01.svg';
    import step302 from '../assets/images/illustration-step-3-02.svg';
    import step303 from '../assets/images/illustration-step-3-03.svg';

    import StepsContact from "./StepsContact";

    export default {
        name: 'steps',
        components: {StepsContact},
        props: {
            afterForm: {
                type: Boolean,
                required: false,
                default: false
            },
        },
        data() {
            return {
                steps: [
                    {
                        before: 'Maschine kostenlos bewerten',
                        imgSrcBefore: step101,
                        after: 'Termin mit Gebrauchtmaschinen-Experten vereinbaren',
                        imgSrcAfter: step301
                    },
                    {
                        imgSrcBefore: step102,
                        before: 'Termin mit Gebrauchtmaschinen-Experten vereinbaren',
                        after: 'Durchführen einer finalen Bewertung vor Ort durch unsere Experten',
                        imgSrcAfter: step302
                    },
                    {
                        imgSrcBefore: step103,
                        before: 'Gebrauchtmaschine verkaufen',
                        after: 'Ankauf Ihrer gebrauchten Baumaschine durch Zeppelin',
                        imgSrcAfter: step303
                    },
                ]
            }
        }
    }
</script>

<style lang="scss">
    .steps-wrapper {
        .steps-gradient {
            @include media-breakpoint-up(md) {
                background-image: linear-gradient(to bottom, $gray-100 80%, $gray-400 100%);
            }
        }

        .step {
            img {
                width: 100%;
                @include media-breakpoint-down(sm) {
                    max-width: 360px;
                }
            }

            &.arrow-indicator {
                &:after {
                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }
            }
        }
    }
</style>
