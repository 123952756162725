import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Condition from './views/Condition'
import Contactform from './views/Contactform'
import ThankYou from './views/ThankYou'
import ErrorPage from './views/ErrorPage'
import TermsOfUse from './views/TermsOfUse'
import DataProtection from './views/DataProtection'
import LegalNotice from './views/LegalNotice'
import Faq from './views/Faq'
import CookieNotice from './views/CookieNotice'
import {checkMachineData, checkConditionData, checkFormComplete} from './services/validateData.service'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior (to, from, savedPosition) {
        // eslint-disable-next-line
        return new Promise((resolve, reject) => {
            /* if user pushes browser history back button */
            if (savedPosition) {
                resolve(savedPosition)
            }

            if (to.hash) {
                resolve({selector: to.hash})
            }

            /* default */
            resolve({x: 0, y: 0})
        })
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/condition',
            name: 'condition',
            component: Condition,
            beforeEnter: (to, from, next) => {
                if (to.path !== from.path) {
                    if (!checkMachineData()) {
                        next('/');
                        // next();
                    }
                }
                next();
            }
        },
        {
            path: '/contactform',
            name: 'contactform',
            component: Contactform,
            beforeEnter: (to, from, next) => {
                if (to.path !== from.path) {
                    if (!checkMachineData() && !checkConditionData()) {
                        next('/');
                        // next();
                    }
                }
                next();
            }
        },
        {
            path: '/thank-you',
            name: 'thank-you',
            component: ThankYou,
            beforeEnter: (to, from, next) => {
                if (to.path !== from.path) {
                    if (!checkFormComplete()) {
                        next('/');
                        // next();
                    }
                }
                next();
            }
        },
        {
            path: '/nutzungsbedingungen',
            name: 'terms-of-use',
            component: TermsOfUse
        },
        {
            path: '/datenschutz',
            name: 'data-protection',
            component: DataProtection
        },
        {
            path: '/impressum',
            name: 'legal-notice',
            component: LegalNotice
        },
        {
            path: '/cookie-hinweise',
            name: 'cookie-notice',
            component: CookieNotice
        },
        {
            path: '/faq',
            name: 'faq',
            component: Faq
        },
        {
            path: '/error',
            name: 'error-page',
            component: ErrorPage
        },
        {
            path: '/index.html',
            redirect: '/'
        },
        {
            path: '*',
            redirect: '/error'
        },
        // {
        //   path: '/about',
        //   name: 'about',
        //   // route level code-splitting
        //   // this generates a separate chunk (about.[hash].js) for this route
        //   // which is lazy-loaded when the route is visited.
        //   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
        // }
    ]
})
