<template>
    <p v-if="contactData.street && contactData.street !== ''">
        Zeppelin Baumaschinen GmbH<br>
        {{contactData.expert_branch}}<br>
        {{fullName}}<br>
        {{contactData.street}}<br>
        {{contactData.zipCode}} {{contactData.city}}<br><br>
        Tel:&nbsp;{{contactData.phone}}<br>
        Mobil:&nbsp;{{contactData.mobile}}<br>
        E-Mail:&nbsp;<a v-if="contactData.email" class="no-color" :href="'mailto:'+contactData.email">{{contactData.email}}</a>
    </p>
</template>

<script>
    export default {
        name: 'steps-contact-address',
        props: ['contactData', 'fullName']
    }
</script>