<template>
    <div class="gbm-footer-wrapper bg-secondary faded-border">
        <div class="container">
            <div class="row no-gutters py-4 font-size-smaller text-white align-items-center text-center">
                <span class="mr-3 my-1 col-12 col-md-auto">Zeppelin BaggerBoerse</span>
                <span class="mr-3 my-1 col-12 col-md-auto">©{{printCurrentYear}} Zeppelin Baumaschinen GmbH</span>
              <router-link :to="{name: 'faq'}" target="_blank"
                           class="text-white footer-link ml-auto mr-3 mt-3 mt-md-1 mb-1 mt-md-0 col-12 col-md-auto">FAQ</router-link>
                <router-link :to="{name: 'terms-of-use'}" target="_blank"
                   class="text-white footer-link mr-3 my-1 col-12 col-md-auto">Nutzungsbedingungen</router-link>
                <router-link :to="{name: 'data-protection'}" target="_blank"
                   class="text-white footer-link mr-3 my-1 col-12 col-md-auto">Datenschutz</router-link>
                <router-link :to="{name: 'legal-notice'}" target="_blank"
                   class="text-white footer-link mr-3 my-1 col-12 col-md-auto">Impressum</router-link>
                <router-link :to="{name: 'cookie-notice'}" target="_blank"
                   class="text-white footer-link mr-3 my-1 col-12 col-md-auto">Cookie Hinweise</router-link>

              <a href="https://www.zeppelin-cat.de" target="_blank"
                   class="text-white footer-link mr-3 mt-3 mt-md-1 mb-1 col-12 col-md-auto">www.zeppelin-cat.de</a>
            </div>
        </div>

        <!--Extra spacer, because Safari and FF don't like margin at the end of the page-->
        <div class="d-md-none footer-spacer" v-if="isOnHome"></div>
    </div>
</template>

<script>
export default {
        name: 'gbm-footer',
        methods: {
          toggleOneTrust() {
              if (window.OneTrust) {
                window.OneTrust.ToggleInfoDisplay();
              }
          }
        },
        computed: {
            isOnHome() {
                return this.$route.name === 'home';
            },
          printCurrentYear(){
            return new Date().getFullYear()
          }
        }
    }
</script>

<style lang="scss">
    .gbm-footer-wrapper {
        // set min-height explicitly so Safari shows the footer
        min-height: 230px;

        @include media-breakpoint-up(md) {
            min-height: 103px;
        }

        @include media-breakpoint-up(lg) {
            min-height: auto;
        }

        .footer-spacer {
            height: $input-height;
        }
    }
</style>