import Vue from 'vue'
import Vuex from 'vuex'
// LOKAL: die 2 APIs gegen machinedata austauschen
// import {machinedata} from './mockdata/machines.mockdata'
import {getApi, postApi} from './services/getApiData.service'
import router from './router'
import VuexPersistence from 'vuex-persist'
import { getElementPosition } from './services/elementPosition.service'
// import { valueIsSpecial } from './services/specialTextMixin.service'
import {selectedData} from "./models/selectedData.model";
import {defaultCountryForZip, formdata} from "./models/formdata.model";
import {contactData} from "./models/contactData.model";

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    key: 'zep-gbm',
    filter: (mutation) => {
        return mutation.type === 'setSubmitCount' ||
            mutation.type === 'setSubmitTime';
    },
    reducer: (state) => {
        return {
            submitCount: state.submitCount,
            submitTime: state.submitTime,
        };
    }
});

const vuexSession = new VuexPersistence({
    storage: window.sessionStorage,
    key: 'zep-gbm',
    filter: (mutation) => {
        return mutation.type === 'setFormData';
    },
    reducer: (state) => {
        return {
            savedFormdata: state.savedFormdata
        };
    }
});

export default new Vuex.Store({
    state: {
        topManufacturers: ['CATERPILLAR', 'HITACHI', 'KOMATSU', 'LIEBHERR', 'VOLVO'],
        selectedData: selectedData,
        machinedata: {},
        countryData: [],
        manufacturerData: null,
        categoryData: null,
        typeData: null,
        machineformPosition: {
            visible: false,
            top: 0
        },
        dataLoading: true,
        countriesLoading: false,
        formCompleted: false,
        savedFormdata: formdata,
        submitCount: 0,
        submitTime: 0,
        formStatus: '',
        formStatusOptions: {
            error: 'error',
            success: 'success',
            old: 'old',
            other: 'other',
            greater5: 'greater5',
            greater6: 'greater6',
            noPriceCalc: 'noPriceCalc'
        },
        contactData: contactData,
        contactFreq: 0,
        reCaptchaConsentGiven: false,
        reCaptchaOneTrustCategory: 'C0002',
    },
    mutations: {
        setMachineData(state, data) {
            state.machinedata = data;
        },
        setCountryData(state, data) {
            state.countryData = data;
        },
        setManufacturerData(state, data) {
          state.manufacturerData = data;
        },
        setCategoryData(state, data) {
          state.categoryData = data;
        },
        setTypeData(state, data) {
          state.typeData = data;
        },
        setDataLoading(state, isLoading) {
            state.dataLoading = isLoading;
        },
        setCountriesLoading(state, isLoading) {
            state.countriesLoading = isLoading;
        },
        setFormCompleted(state, completed) {
            state.formCompleted = completed;
        },
        setFormData(state, data) {
            state.savedFormdata = data;
        },
        setSubmitCount(state, count) {
          state.submitCount = count;
        },
        setSubmitTime(state, time) {
            state.submitTime = time;
        },
        setFormStatus(state, status) {
          state.formStatus = status;
        },
        setContactData(state, data) {
            state.contactData = data;
        },
        setMachineformPosition(state, el) {
            state.machineformPosition = getElementPosition(el);
        },
        setUserMachineData(state, content) {
            state.selectedData.manufacturer = content.manufacturer;
            state.selectedData.category = content.category;
            state.selectedData.type = content.type;
            state.selectedData.isSpecial = content.isSpecial;
            // console.log('machine data set', state.selectedData);
        },
        setUserConditionData(state, content) {
            state.selectedData.year = content.year;
            state.selectedData.hours = content.hours;
            state.selectedData.isOld = content.isOld;
            state.selectedData.features = content.features;
            // console.log('condition set', state.selectedData);
        },
        resetSelectedData(state) {
            state.selectedData = {...selectedData};
            state.manufacturerData = null;
            state.categoryData = null;
            state.typeData = null;
        },
        setReCaptchaConsent(state, content) {
            state.reCaptchaConsentGiven = content;
        },
    },
    actions: {
        async getMachineData({state, commit}) {
            if (state.machinedata.length) {
                return;
            }

            // LOKAL: Def von data austauschen
            // const data = machinedata.selectionList;
            const data = await getApi('public/selection', (response) => {
                return response ? response.selectionList : undefined;
            });

            if (!data) {
                commit('setMachineData', []);
                commit('setDataLoading', false);
                return;
            }

            const top = [];

            // add top OEMs
            data.forEach(oem => {
                if (state.topManufacturers.indexOf(oem.n) !== -1) {
                    top.push(oem);
                }
            });

            // sort top OEMs
            top.sort(function (a, b) {
                return state.topManufacturers.indexOf(a.n) - state.topManufacturers.indexOf(b.n);
            });

            commit('setMachineData', [...top, ...data]);
            commit('setDataLoading', false);

        },
        async getCountryData({state, commit}) {
            if (state.countryData.length) {
                return;
            }

            commit('setCountriesLoading', true);

            // LOKAL: Def von data austauschen
            // let data = await new Promise(resolve => setTimeout(() => {resolve(['Deutschland', 'Österreich', 'Schweiz'])}, 2000))
            let data = await getApi('public/countries', (response) => {
                return response ? response : undefined;
            });

            if (!data) {
                commit('setCountryData', []);
                commit('setCountriesLoading', false);
                return;
            }

            // Add Germany, deduplicate and sort
            data.push(defaultCountryForZip);
            data = data.reduce((countryList, current) => {
                if (!countryList.includes(current)) {
                    return [...countryList, current];
                } else {
                    return countryList;
                }
            }, []).sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()));

            commit('setCountryData', data);
            commit('setCountriesLoading', false);
        },
        sendFormData({state, commit, dispatch}, data) {
            // LOKAL: reinkommentieren
            // console.log('data to send', JSON.stringify(data.machinedata));
            // const response = {};
            // response.responseStatus = state.formStatusOptions.greater5;
            // response.managerContact = {
            //     last_name_de: 'bbb',
            //     first_name_de: 'aaa',
            //     filePicUrl: '',
            //     street: 'Straße 3',
            //     zipCode: '81800',
            //     city: 'München',
            //     email: 'aaa@bbb.com',
            //     mobile: '2222222222',
            //     phone: '111111111',
            //     fax: 'fax423456467'
            // };

            // LOKAL: nächste Zeile rauskommentieren
            postApi('public/requestPrice', data, (response) => {
                commit('setFormStatus', response.responseStatus);
                commit('setContactData', response.managerContact);
                commit('setFormCompleted', true);
                // console.log('FORM RESPONSE', response);

                if (response.responseStatus === state.formStatusOptions.error) {
                    router.push({name: 'error-page'});
                    return;
                }

                // commit('setSubmitCount', ++state.submitCount);
                dispatch('handleSubmitCount', true);
                commit('resetSelectedData');

                router.push({name: 'thank-you'});
                // LOKAL: nächste Zeile rauskommentieren
            })
        },
        handleSubmitCount({state, commit}, increment) {
            const currentTime = new Date().getTime();
            // 24h in ms
            const day = 86400000;

            // console.log('time from storage:', state.submitTime);
            // console.log('time diff > 24h:', (currentTime - state.submitTime) > day);
            // console.log('times: ', new Date(state.submitTime), new Date(currentTime))

            // reset if more than 23h have passed
            if (state.submitTime !== 0 && (currentTime - state.submitTime > day)) {
                commit('setSubmitTime', 0);
                commit('setSubmitCount', 0);
                // console.log('more than 24h, reset', state.submitCount, new Date(state.submitTime));
            }

            // increment count on form submit
            if (increment) {
                if (state.submitTime === 0) {
                    commit('setSubmitTime', currentTime);
                }
                commit('setSubmitCount', ++state.submitCount);
                // console.log('increment count', state.submitCount, new Date(state.submitTime));
            }
        },
        scrollToForm({state}) {
            window.scrollTo({top: state.machineformPosition.top, behavior: 'smooth'});
        }
    },
    plugins: [vuexLocal.plugin, vuexSession.plugin]
})
