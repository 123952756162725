import { render, staticRenderFns } from "./CookieNotice.vue?vue&type=template&id=5ab22564&"
import script from "./CookieNotice.vue?vue&type=script&lang=js&"
export * from "./CookieNotice.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "CookieNotice.vue"
export default component.exports