<template>
    <div class="condition-wrapper">

        <Stepper :current-step="1"></Stepper>

        <div class="container">
            <div class="row">
                <div class="col-12 mx-auto text-center">
                    <h5 class="mt-5 mb-4">Weitere Daten Ihrer Gebrauchtmaschine</h5>
                </div>
            </div>
            <div class="row">
                <div class="custom-form font-mono col-12 col-md-8 col-lg-4 mx-auto font-size-small">
                    <!-- input year -->
                    <!--<div :class="{ 'form-error': $v.condition.year.$error }" v-if="!isSpecial">-->
                        <!--<label class="mb-1 mt-3 font-weight-bold">Baujahr</label>-->
                        <!--<v-select-->
                                <!--class="dropdown-overwrite"-->
                                <!--v-model="condition.year"-->
                                <!--:options="yearOptions"-->
                                <!--placeholder="Baujahr auswählen"-->
                                <!--:searchable="false"></v-select>-->
                        <!--<div class="form-group-error" v-if="!$v.condition.year.required">Bitte geben Sie ein Baujahr ein</div>-->
                    <!--</div>-->

                    <FormRow
                            :validator-data="$v.condition.year"
                            v-if="!isSpecial"
                            row-type="select"
                            label="Baujahr"
                            :error-data="[
                            {value: !$v.condition.year.required, text: 'Bitte geben Sie ein Baujahr ein'}
                            ]">
                        <select-dropdown
                                v-model="condition.year"
                                :options="yearOptions"
                                placeholder="Baujahr auswählen"></select-dropdown>
                    </FormRow>

                    <!-- input hours -->
                    <!--<div class="form-group pt-3 mb-1" :class="{ 'form-error': $v.condition.hours.$error }" v-if="!isSpecial">-->
                        <!--<label for="hours" class="mb-1 mt-3 font-weight-bold">Betriebsstunden (Bh)</label>-->
                        <!--<div class="gmp-icon" :class="[-->
                            <!--{'icon-warning-input': $v.condition.hours.$error},-->
                            <!--{'icon-success-input': !$v.condition.hours.$invalid && $v.condition.hours.$model !== ''}]">-->
                            <!--<input class="form-control"-->
                                   <!--type="number"-->
                                   <!--id="hours"-->
                                   <!--:value="condition.hours"-->
                                   <!--placeholder="Betriebsstunden (Bh) auswählen"-->
                                   <!--@change="setHours($event.target.value)"/>-->
                        <!--</div>-->

                        <!--<div class="form-group-error" v-if="!$v.condition.hours.required">Bitte geben Sie die Betriebsstunden ein</div>-->
                        <!--<div class="form-group-error" v-if="!$v.condition.hours.numeric || !$v.condition.hours.maxValue">Bitte tragen Sie eine Zahl zwischen 0 - 25.000 ein</div>-->
                    <!--</div>-->

                    <FormRow class="pt-3"
                            :validator-data="$v.condition.hours"
                            v-if="!isSpecial"
                            row-type="input"
                            label="Betriebsstunden (Bh)"
                            :error-data="[
                            {value: !$v.condition.hours.required, text: 'Bitte geben Sie die Betriebsstunden ein'},
                            {value: !$v.condition.hours.numeric || !$v.condition.hours.maxValue, text: 'Bitte tragen Sie eine Zahl zwischen 0 - 25.000 ein'}
                            ]">
                        <input class="form-control"
                               type="number"
                               id="hours"
                               :value="condition.hours"
                               placeholder="Betriebsstunden (Bh) auswählen"
                               @change="setHours($event.target.value)"/>
                    </FormRow>

                    <!--input text-->
                    <!--<div class="form-group pt-3 mb-1" :class="{ 'form-error': $v.condition.features.$error }" v-if="isSpecial || condition.isOld">-->
                    <!--<label for="description">Besonderheiten</label>-->
                    <!--<div class="gmp-icon" :class="[-->
                    <!--{'icon-warning-input': $v.condition.features.$error},-->
                    <!--{'icon-success-input': !$v.condition.features.$invalid && $v.condition.features.$model !== ''}]">-->
                    <!--<textarea class="form-control"-->
                              <!--id="description"-->
                              <!--:value="condition.features"-->
                              <!--placeholder="Beispiel: Allgemeiner Zustand, Baujahr, Betriebsstunden, Anbauteile etc."-->
                              <!--@input="setFeatures($event.target.value)"-->
                    <!--&gt;</textarea>-->
                    <!--</div>-->

                    <!--<div class="form-group-error" v-if="!$v.condition.features.maxLength">Bitte geben Sie weniger als 300 Zeichen ein.-->
                    <!--</div>-->
                    <!--</div>-->

                    <FormRow class="pt-3"
                             :validator-data="$v.condition.features"
                             v-if="isSpecial || condition.isOld"
                             row-type="input"
                             label="Besonderheiten"
                             :error-data="[
                            {value: !$v.condition.features.maxLength, text: 'Bitte geben Sie weniger als 300 Zeichen ein'}
                            ]">
                        <textarea class="form-control"
                                  id="description"
                                  :value="condition.features"
                                  placeholder="Beispiel: Allgemeiner Zustand, Baujahr, Betriebsstunden, Anbauteile etc."
                                  @input="setFeatures($event.target.value)"
                        ></textarea>
                    </FormRow>

                    <button id="gbm-eval-condition" class="btn btn-primary btn-block font-reset font-weight-bold mt-4 mb-5" @click="handleNext()">
                        Bewertung Abschliessen
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import Stepper from "../components/Stepper";
    import {numeric, maxValue, requiredUnless, maxLength} from 'vuelidate/lib/validators'
    import FormRow from "../components/FormRow";
    import SelectDropdown from "../components/SelectDropdown";

    export default {
        name: 'condition',
        components: {
            Stepper, FormRow, SelectDropdown
        },
        data() {
            return {
                condition: {
                    year: '',
                    hours: '',
                    features: '',
                    isOld: false
                },
                olderText: 'älter'
            }
        },
        mounted() {
            if (this.isSpecial) {
                this.condition.features = this.selectedData.features;
            } else {
                this.condition.year = this.selectedData.year;
                this.condition.hours = this.selectedData.hours;
                this.condition.isOld = this.selectedData.isOld;
                this.condition.features = this.selectedData.features;
            }
        },
        validations: {
            condition: {
                year: {
                    required: requiredUnless(function() {return this.isSpecial})
                },
                hours: {
                    required: requiredUnless(function() {return this.isSpecial}),
                    numeric,
                    maxValue: maxValue(25000),
                },
                features: {
                    maxLength: maxLength(300)
                }
            }
        },
        watch: {
            'condition.year': function (value) {
                this.condition.isOld = value === this.olderText;
            },
        },
        methods: {
            handleNext() {
                this.$v.$touch();

                if (!this.$v.$invalid) {
                    // console.log('CONDITION SUCCESS');
                    // console.log(this.condition);

                    // console.log({
                    //     'baujahr': this.condition.year,
                    //     'betriebsstunden': this.condition.hours
                    // });
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'baujahr': this.condition.year,
                        'betriebsstunden': this.condition.hours
                    });

                    this.$store.commit('setUserConditionData', this.condition);
                    this.$router.push({name: 'contactform'})
                }
            },
            setHours(value) {
                this.condition.hours = value;
                this.$v.condition.hours.$touch();
            },
            setFeatures(value) {
                this.condition.features = value;
                this.$v.condition.features.$touch();
            }
        },
        computed: {
            ...mapState({
                selectedData: state => state.selectedData,
                years: state => state.typeData,
                isSpecial: state => state.selectedData.isSpecial
            }),
            yearOptions() {
                if (this.years) {
                    const currYear = new Date().getFullYear();
                    const startYear = parseInt(this.years.bn);
                    const endYear = parseInt(this.years.bx);
                    let result = [];

                    for (let i = startYear; i <= endYear; i++) {
                        result.push(currYear - i);
                    }

                    if (endYear >= 20) {
                        result.push(this.olderText);
                    }

                    return result;
                }
                return [];
            }
        }
    }
</script>
