<template>
    <div class="mt-5">
        <div class="row mx-auto mb-5">
            <div class="col-12 col-md-6 text-center mx-auto mt-3 mt-md-5">
                <div v-if="formStatus === formStatusOptions.success || formStatus === ''">
                    <h4>Ihre persönliche Bewertung wurde an Sie versandt!</h4>
                    <p>Vielen Dank! Prüfen Sie bitte Ihr E-Mail Postfach, um die Bewertung Ihrer Baumaschine
                        einzusehen.</p>
                </div>

                <div v-if="formStatus === formStatusOptions.other || formStatus === formStatusOptions.old">
                    <h4>Ihre Anfrage wurde versandt!</h4>
                    <p>Vielen Dank! Prüfen Sie bitte Ihr E-Mail Postfach, um die Bestätigung Ihrer Anfrage
                        einzusehen.</p>
                </div>

                <div v-if="formStatus === formStatusOptions.greater5">
                    <h4>Ihre Anfrage wurde versandt!</h4>
                    <p>Vielen Dank! Wir haben Ihre Anfragen erhalten und melden uns gesammelt zu allen Anfragen bei
                        Ihnen.</p>
                </div>

                <div v-if="formStatus === formStatusOptions.greater6">
                    <h4>Wir haben Ihre Anfragen erhalten!</h4>
                    <p v-if="!formdata.nocontact">Vielen Dank! Wir haben bereits mehrere Anfragen von Ihnen erhalten und melden uns bei Ihnen in
                        Kürze.</p>
                    <p v-else>Vielen Dank! Bitte wenden Sie sich bei mehreren Anfragen an den Gebrauchtmaschinen-Experten, welcher Ihnen in den Mails zu Ihren vorherigen Anfragen genannt wurde.</p>
                </div>

                <div v-if="formStatus === formStatusOptions.noPriceCalc">
                    <h4>Ihre Anfrage wurde versandt!</h4>
                    <p>Da es sich bei Ihrer Maschine um eine Produktionsmaschine handelt, können wir eine valide Bewertung erst bei Begutachtung vor Ort vornehmen.</p>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-8 col-lg-6 mx-auto mt-3">
                        <router-link :to="{name: 'home'}"
                                     class="btn btn-primary btn-block mt-1 font-weight-bold text-uppercase">
                            Neue Bewertung starten
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <Steps :afterForm="true"></Steps>

        <crosslink-teaser></crosslink-teaser>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import Steps from "../components/Steps";
    import CrosslinkTeaser from "../components/CrosslinkTeaser";

    export default {
        name: 'thank-you',
        components: {CrosslinkTeaser, Steps},
        destroyed() {
          this.$store.commit('setFormCompleted', false);
        },
        computed: {
            ...mapState({
                formStatus : state => state.formStatus,
                formStatusOptions: state => state.formStatusOptions,
                formdata: state => state.savedFormdata
            })
        }
    }
</script>