<template>
    <div class="container mt-6 mb-5">
        <h1>Impressum</h1>

        <div class="mx-auto font-weight-light mt-5">
            <p>Angaben gem. § 5 TMG</p>

            <p>Sitz:<br>
                Zeppelin Baumaschinen GmbH<br>
                Graf-Zeppelin-Platz 1<br>
                85748 Garching bei München<br>
                Telefon: +49 (0)89 32 00 00<br>
                Telefax: +49 (0)89 32 00 0 111<br>
                Email: <a class="no-color" href="mailto:zeppelin-cat@zeppelin.com">zeppelin-cat@zeppelin.com</a></p>

            <p>Vertreten durch:</p>

            <p>Vorsitzender des Aufsichtsrats: Michael Heidemann</p>

            <p>Geschäftsführer:<br>
                Holger Schulz (Vorsitzender der Geschäftsführung)<br>
                Thomas Weber (Stellvertretender Vorsitzender der Geschäftsführung)<br>
                Dr. Andreas Kamm<br>
                Philip Wolters
            </p>


            <p>Eingetragen im Handelsregister:<br>
                Amtsgericht München unter<br>
                HRB München 107767<br>
                Ust-ID-Nr.: DE-145374108<br>

            <p>Verantwortlicher im Sinne von § 55 Abs. 2 RStV:<br>
                Zeppelin Baumaschinen GmbH<br>
                Ilka Kallin<br>
                Graf-Zeppelin-Platz 1<br>
                85748 Garching bei München</p>

            <p><br>Wir sind zur Teilnahme an einem Streitbeilegungsverfahren
                vor einer Verbraucherschlichtungsstelle nicht verpflichtet
                und nehmen auch nicht freiwillig an einem solchen Verfahren teil.</p>

            <p>Plattform der EU-Kommission zur Online-Streitbeilegung:
                <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.show&lng=DE" class="no-color" target="_blank">www.ec.europa.eu</a>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'legal-notice'
    }
</script>