<template>
  <div class="container mt-6 mb-5">
    <h1>Cookie Hinweise</h1>

    <div class="mx-auto font-weight-light mt-5">
      <a class="btn btn-warning text-uppercase font-weight-bold px-5 mt-3 mb-6" href="#" @click.stop.prevent="toggleOneTrust">Cookie Einstellungen</a>
      <div id="ot-sdk-cookie-policy"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'cookie-notice',
  methods: {
    toggleOneTrust() {
      if (window.OneTrust) {
        window.OneTrust.ToggleInfoDisplay();
      }
    },
  }
}
</script>