import { render, staticRenderFns } from "./ErrorPage.vue?vue&type=template&id=37bd9048&"
import script from "./ErrorPage.vue?vue&type=script&lang=js&"
export * from "./ErrorPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "ErrorPage.vue"
export default component.exports