<template>
  <div class="container mt-6 mb-5 overflow-hidden">
    <h1>FAQ</h1>
    <div class="mx-auto font-weight-light mt-5">
      <faq-entry
          v-for="(entry, index) in faqDataEntries"
          :key="'faq-entry' + index"
          :index="index"
          :faq-heading="entry.question"
          :faq-content="entry.answer">
      </faq-entry>
    </div>
  </div>
</template>

<script>
import FaqEntry from "../components/FaqEntry";
import faqData from "../models/faq.js"
import {createFaqSchemaScript, destroyFaqSchemaScript} from "../services/faqSchema.service";

export default {
  name: 'faq',
  components: {FaqEntry},
  data: () => {
    return {
      faqType: 'full',
    }
  },
  computed: {
    faqDataEntries() {
      return faqData;
    }
  },
  created() {
    createFaqSchemaScript(this.faqType, this.faqDataEntries);
  },
  beforeDestroy() {
    destroyFaqSchemaScript(this.faqType);
  }
}
</script>