<template>
  <div class="pt-4 pb-6">
    <div class="container">
      <h3 class="mb-4 mt-5">FAQ</h3>
      <div class="mx-auto font-weight-light">
        <faq-entry
            v-for="(entry, index) in faqDataEntries"
            :key="'faq-entry' + index"
            :index="index"
            :faq-heading="entry.question"
            :faq-content="entry.answer">
        </faq-entry>
      </div>
      <router-link :to="{name: 'faq'}"
                   class="btn btn-warning text-uppercase font-weight-bold px-5 mt-5" target="_blank">Alle FAQ ansehen
      </router-link>
    </div>
  </div>
</template>

<script>
import FaqEntry from "../components/FaqEntry";
import faqData from "../models/faq.js"
import {createFaqSchemaScript, destroyFaqSchemaScript} from "../services/faqSchema.service";

export default {
  name: 'faq-overview',
  components: {FaqEntry},
  data: () => {
    return {
      faqType: 'home',
    }
  },
  computed: {
    faqDataEntries() {
      return faqData.slice(0, 5);
    }
  },
  created() {
    createFaqSchemaScript(this.faqType, this.faqDataEntries);
  },
  beforeDestroy() {
    destroyFaqSchemaScript(this.faqType);
  }
}
</script>