import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import vSelect from '../node_modules/vue-select/dist/vue-select'
import VTooltip from 'v-tooltip'
import 'whatwg-fetch'
// import { VueReCaptcha } from 'vue-recaptcha-v3'

// Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_KEY })

Vue.config.productionTip = false;

Vue.component('v-select', vSelect);

Vue.use(VTooltip);

Vue.use(Vuelidate);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
