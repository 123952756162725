<template>
  <div
      class="faq-entry | border-secondary border-bottom"
      :class="{'faq-entry__open': entryOpen}"
      :aria-controls="`faq-content-${index}`"
      :aria-expanded="entryOpen ? 'true' : 'false'"
      :id="`faq-entry-${index}`">
    <div class="faq-heading | d-flex align-items-center | px-3" @click="handleEntryClick">
      <div class="d-flex align-items-center justify-content-between flex-grow-1 | py-3">
        <h6 class="mb-0">{{ faqHeading }}</h6>
        <span class="faq-indicator gmp-icon icon-dropdown"></span>
      </div>
    </div>

    <div
        ref="faqContent"
        class="faq-content"
        :aria-hidden="!entryOpen ? 'true' : 'false'"
        :id="`faq-content-${index}`"
        :style="{'max-height': contentHeight}">
      <div class="px-3 pb-4" v-html="faqContent"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'faq-entry',
  props: {
    faqHeading: {
      type: String,
      required: true,
    },
    faqContent: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      entryOpen: false,
      contentHeight: null,
    }
  },
  methods: {
    handleEntryClick() {
      const domElement = this.$refs.faqContent;
      if (!domElement) {
        return;
      }

      if (this.entryOpen) {
        this.entryOpen = false;
        this.contentHeight = null;
      } else {
        this.entryOpen = true;
        this.contentHeight = domElement.scrollHeight + 'px';
      }
    }
  }
}
</script>

<style lang="scss">
.faq-entry {
  .faq-heading {
    cursor: pointer;
    user-select: none;
    min-height: 62px;
    transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .faq-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .faq-indicator {
    &:before {
      display: inline-block;
      font-style: normal;
      font-weight: 500;
      text-decoration: inherit;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      border: none;
      width: 10px;
      transform: rotate(0deg);
      transition: transform 0.2s ease-in;
    }
  }

  &.faq-entry__open {
    .faq-heading {
      min-height: 94px;
    }
    .faq-indicator {
      &:before {
        transform: rotate(180deg);
        transition: transform 0.2s ease-in;
      }
    }
  }
}
</style>