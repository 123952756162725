<template>
    <div id="app" v-cloak>
        <Gbm-Header></Gbm-Header>
        <div class="views-wrapper row no-gutters">
            <router-view/>
        </div>
        <Gbm-Footer></Gbm-Footer>
    </div>
</template>

<script>
    import GbmHeader from "./components/page_basics/Gbm-Header";
    import GbmFooter from "./components/page_basics/Gbm-Footer";

    export default {
        components: {GbmFooter, GbmHeader},
        mounted() {
          // Handle OneTrust changes
          // this.initOneTrust()
          // document.addEventListener('oneTrustLoaded', () => {
          //   this.initOneTrust();
          // })
        },
      data() {
          return {
            oneTrustLoaded: false
          }
      },
      methods: {
          initOneTrust() {
            const reCaptchaCategory = this.$store.state.reCaptchaOneTrustCategory;
            if (!this.oneTrustLoaded && window.OneTrust) {
              this.$store.commit('setReCaptchaConsent', window.OnetrustActiveGroups.indexOf(`,${reCaptchaCategory},`) > -1)

              window.OneTrust.OnConsentChanged((e) => {
                this.$store.commit('setReCaptchaConsent', e.detail.indexOf(reCaptchaCategory) > -1)
              });
              this.oneTrustLoaded = true;
            }
          }
      }
    }
</script>

<style lang="scss" src="./style/main.scss"></style>