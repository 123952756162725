export function getElementPosition(el) {
    let top = el.offsetTop;
    const height = el.offsetHeight;
    // offset to account for sticky header
    const offsetHeader = 100;

    while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
    }

    const isVisible = (
        (top + offsetHeader) < (window.pageYOffset + window.innerHeight) &&
        (top + height - offsetHeader) > window.pageYOffset
    );

    return {visible: isVisible, top: top - offsetHeader};
}