var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"condition-wrapper"},[_c('Stepper',{attrs:{"current-step":1}}),_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"custom-form font-mono col-12 col-md-8 col-lg-4 mx-auto font-size-small"},[(!_vm.isSpecial)?_c('FormRow',{attrs:{"validator-data":_vm.$v.condition.year,"row-type":"select","label":"Baujahr","error-data":[
                        {value: !_vm.$v.condition.year.required, text: 'Bitte geben Sie ein Baujahr ein'}
                        ]}},[_c('select-dropdown',{attrs:{"options":_vm.yearOptions,"placeholder":"Baujahr auswählen"},model:{value:(_vm.condition.year),callback:function ($$v) {_vm.$set(_vm.condition, "year", $$v)},expression:"condition.year"}})],1):_vm._e(),(!_vm.isSpecial)?_c('FormRow',{staticClass:"pt-3",attrs:{"validator-data":_vm.$v.condition.hours,"row-type":"input","label":"Betriebsstunden (Bh)","error-data":[
                        {value: !_vm.$v.condition.hours.required, text: 'Bitte geben Sie die Betriebsstunden ein'},
                        {value: !_vm.$v.condition.hours.numeric || !_vm.$v.condition.hours.maxValue, text: 'Bitte tragen Sie eine Zahl zwischen 0 - 25.000 ein'}
                        ]}},[_c('input',{staticClass:"form-control",attrs:{"type":"number","id":"hours","placeholder":"Betriebsstunden (Bh) auswählen"},domProps:{"value":_vm.condition.hours},on:{"change":function($event){_vm.setHours($event.target.value)}}})]):_vm._e(),(_vm.isSpecial || _vm.condition.isOld)?_c('FormRow',{staticClass:"pt-3",attrs:{"validator-data":_vm.$v.condition.features,"row-type":"input","label":"Besonderheiten","error-data":[
                        {value: !_vm.$v.condition.features.maxLength, text: 'Bitte geben Sie weniger als 300 Zeichen ein'}
                        ]}},[_c('textarea',{staticClass:"form-control",attrs:{"id":"description","placeholder":"Beispiel: Allgemeiner Zustand, Baujahr, Betriebsstunden, Anbauteile etc."},domProps:{"value":_vm.condition.features},on:{"input":function($event){_vm.setFeatures($event.target.value)}}})]):_vm._e(),_c('button',{staticClass:"btn btn-primary btn-block font-reset font-weight-bold mt-4 mb-5",attrs:{"id":"gbm-eval-condition"},on:{"click":function($event){_vm.handleNext()}}},[_vm._v("\n                    Bewertung Abschliessen\n                ")])],1)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mx-auto text-center"},[_c('h5',{staticClass:"mt-5 mb-4"},[_vm._v("Weitere Daten Ihrer Gebrauchtmaschine")])])])}]

export { render, staticRenderFns }