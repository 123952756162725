export const defaultCountryForZip = 'Deutschland';

export const formdata = {
    email: '',
    country: '',
    zip: '',
    salutation: '',
    name: '',
    lastname: '',
    firm: '',
    tel: '',
    optIn: null
};