<template>
    <div class="accordeon-row-wrapper" :class="{'row-open': rowOpen}">
        <div class="accordeon-row pt-2 pb-1" @click="toggleRow()" @mousedown="preventTextSelection($event)">
            <span class="font-weight-bold" v-if="!rowOpen">+&nbsp;</span>
            <span class="font-weight-bold" v-else>−&nbsp;</span>

            <span class="text-warning font-weight-bold">{{ rowText }}</span>
        </div>
        <div class="accordeon-detail">
            <p class="mb-0">
                {{ detailText }}
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'accordeon-row',
        props: {
            rowText: {
                type: String,
                required: true
            },
            detailText: {
                type: String,
                required: true
            },
        },
        data() {
            return {
                rowOpen: false,
                toggleSign: '+'
            }
        },
        methods: {
            toggleRow() {
                this.rowOpen = !this.rowOpen;
            },
            preventTextSelection(e) {
                e.preventDefault();
            }
        }
    }
</script>

<style lang="scss">
    .accordeon-row-wrapper {
        .accordeon-row {
            cursor: pointer;
        }

        .accordeon-detail {
            line-height: 1.3;
            max-height: 0;
            opacity: 0;
            transition: max-height 0.5s ease-in, opacity 0.7s ease-in;
            overflow: hidden;
        }

        &.row-open {
            .accordeon-detail {
                max-height: 150px;
                opacity: 1;
                transform: scaleY(1);
            }
        }
    }
</style>