<template>
    <div class="condition-wrapper">

        <Stepper :current-step="2"></Stepper>

        <div class="container pt-5">
            <div class="row" v-if="showValueGrafik">
                <div class="col-12 mx-auto text-center">
                    <h5 class="mb-4">Wir haben erfolgreich einen Wert für Ihre Gebrauchtmaschine ermittelt!</h5>
                    <img src="@/assets/images/wert.png" alt="Ermittelten Wert der Gebrauchtmaschine">
                    <p class="font-size-smaller text-monospace mt-2">
                        Bei dem ermittelte Wert handelt es sich um einen Richtwert (zzgl. gesetzlicher MwSt.).<br>Der tatsächliche Ankaufswert ist individuell durch einen Gebrauchtmaschinen-Experten festzustellen sowie abhängig von Zustand, Anbauteilen und der Marktlage.
                    </p>
                </div>
            </div>

            <div class="row" v-if="!isSpecialOrOld">
                <div class="col-12 mx-auto text-center">
                    <h5 class="mt-3 mb-4">Gerne schicken wir Ihnen das Ergebnis per E-Mail zu:</h5>
                </div>
            </div>

            <div class="row" v-if="isSpecialOrOld">
                <div class="col-12 mx-auto text-center">
                    <h5 class="mb-2">Gerne finden wir einen Gebrauchtmaschinen-Experten für Sie!</h5>
                    <p class="mb-4">
                        Für eine valide Bewertung Ihrer Maschine sind leider noch zu viele Unbekannte im Spiel.<br>
                        Das können wir durch eine Begutachtung vor Ort ändern.
                    </p>

                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-8 col-lg-5 mx-auto font-size-small">
                    <form class="custom-form font-mono col-12 col-md-10 mx-auto pb-5 px-0" autocomplete="on">
                        <!--input email-->
                        <FormRow
                                :validator-data="$v.formdata.email"
                                row-type="input"
                                label="E-Mail Adresse*"
                                :error-data="[
                                {value: !$v.formdata.email.email || !$v.formdata.email.required, text: 'Bitte geben Sie eine gültige E-Mail Adresse ein'},
                                {value: !$v.formdata.email.maxLength, text: 'Bitte geben Sie weniger als 50 Zeichen ein'}
                                ]">
                                <input class="form-control"
                                       type="email"
                                       id="email"
                                       name="email"
                                       autocomplete="email"
                                       :value="formdata.email"
                                       placeholder="Beispiel: max.mustermann@mail.de"
                                       @change="setData($event.target.value, 'email')"/>
                        </FormRow>

                        <accordeon-row
                                row-text="Wieso wir diese Daten abfragen"
                                detail-text="Ihre E-Mail-Adresse benötigen wir für die Zusendung der Bewertung und für die Kontaktaufnahme unseres Gebrauchtmaschinen-Experten für eine Terminvereinbarung mit Ihnen."></accordeon-row>

                        <!--input Country-->
                        <FormRow
                          :validator-data="$v.formdata.country"
                          row-type="select"
                          label="Land*"
                          :error-data="[
                                  {value: !$v.formdata.country.required, text: 'Bitte geben Sie Ihr Land ein'},
                                ]">
                            <select-dropdown
                              v-model="formdata.country"
                              :options="countryOptions"
                              :is-loading="countriesLoading"
                              placeholder="Bitte auswählen"></select-dropdown>
                        </FormRow>

                        <!--input ZIP-->
                        <FormRow
                                v-if="countryIsGermany"
                                :validator-data="$v.formdata.zip"
                                row-type="input"
                                label="Postleitzahl (PLZ)*"
                                :error-data="[
                                {value: !$v.formdata.zip.isZip || !$v.formdata.zip.required, text: 'Bitte geben Sie eine 5-stellige Postleitzahl ein'},
                                {value: !$v.formdata.zip.zipAvailable, text: 'Es tut uns leid! Diese Postleitzahl liegt leider nicht in unserem Einzugsgebiet. Bitte nutzen Sie für Ihre Anfrage das Ankaufsformular auf dieser Seite: <a href=https://www.zeppelin-cat.de/aktuelles/ankauf target=_blank class=no-color>https://www.zeppelin-cat.de/aktuelles/ankauf</a>'}
                                ]">
                            <input class="form-control"
                                   type="tel"
                                   id="zip"
                                   name="zip"
                                   autocomplete="postal-code"
                                   :value="formdata.zip"
                                   placeholder="Beispiel: 10115"
                                   @change="setData($event.target.value, 'zip')"/>
                        </FormRow>

                        <accordeon-row
                                row-text="Wieso wir diese Daten abfragen"
                                detail-text="Ihr Land bzw. Ihre deutsche PLZ benötigen wir für die korrekte Zuweisung Ihrer Anfrage an einen unserer regionalen Gebrauchtmaschinen-Experten."></accordeon-row>

                        <!--input salutation-->
                        <FormRow
                                :validator-data="$v.formdata.salutation"
                                row-type="select"
                                label="Anrede">
                            <select-dropdown
                                    v-model="formdata.salutation"
                                    :options="salutationOptions"
                                    placeholder="Bitte auswählen"></select-dropdown>
                        </FormRow>

                        <!--input name-->
                        <FormRow
                                :validator-data="$v.formdata.name"
                                row-type="input"
                                label="Vorname*"
                                :error-data="[
                                {value: !$v.formdata.name.required, text: 'Bitte geben Sie Ihren Vornamen ein'},
                                {value: !$v.formdata.name.isName, text: 'Bitte geben Sie nur Buchstaben ein'},
                                {value: !$v.formdata.name.maxLength, text: 'Bitte geben Sie weniger als 20 Zeichen ein'}
                                ]">
                            <input class="form-control"
                                   type="text"
                                   id="name"
                                   name="firstname"
                                   autocomplete="given-name"
                                   :value="formdata.name"
                                   placeholder="Beispiel: Max"
                                   @change="setData($event.target.value, 'name')"/>
                        </FormRow>

                        <!--input family name-->
                        <FormRow
                                :validator-data="$v.formdata.lastname"
                                row-type="input"
                                label="Nachname*"
                                :error-data="[
                                {value: !$v.formdata.lastname.required, text: 'Bitte geben Sie Ihren Nachnamen ein'},
                                {value: !$v.formdata.lastname.isName, text: 'Bitte geben Sie nur Buchstaben ein'},
                                {value: !$v.formdata.lastname.maxLength, text: 'Bitte geben Sie weniger als 50 Zeichen ein'}
                                ]">
                            <input class="form-control"
                                   type="text"
                                   id="lastname"
                                   name="lastname"
                                   autocomplete="family-name"
                                   :value="formdata.lastname"
                                   placeholder="Beispiel: Mustermann"
                                   @change="setData($event.target.value, 'lastname')"/>
                        </FormRow>

                        <!--input firmenname-->
                        <FormRow
                                :validator-data="$v.formdata.firm"
                                row-type="input"
                                label="Firmenname"
                                :error-data="[
                                {value: !$v.formdata.firm.required, text: 'Bitte geben Sie Ihren Firmennamen ein'},
                                {value: !$v.formdata.firm.maxLength, text: 'Bitte geben Sie weniger als 100 Zeichen ein'}
                                ]">
                            <input class="form-control"
                                   type="text"
                                   id="firm"
                                   autocomplete="organization"
                                   :value="formdata.firm"
                                   placeholder="Beispiel: Max Mustermann Bau GmbH"
                                   @change="setData($event.target.value, 'firm')"/>
                        </FormRow>

                        <!--input tel-->
                        <FormRow
                                :validator-data="$v.formdata.tel"
                                row-type="input"
                                label="Telefon-Nr."
                                :error-data="[
                                {value: !$v.formdata.tel.numeric, text: 'Bitte geben Sie nur Zahlen ein'},
                                {value: !$v.formdata.tel.maxLength, text: 'Bitte geben Sie weniger als 30 Zeichen ein'}
                                ]">
                            <input class="form-control"
                                   type="tel"
                                   id="phone"
                                   name="phone"
                                   autocomplete="tel"
                                   :value="formdata.tel"
                                   placeholder="Beispiel: 08912345678"
                                   @change="setData($event.target.value, 'tel')"/>
                        </FormRow>
                        <div>
                            <span class="font-size-small font-reset mr-2">Falls Sie eine telefonische Kontaktaufnahme durch unsere
                                Gebrauchtmaschinenexperten wünschen, tragen Sie hier bitte Ihre Telefonnummer ein.</span>
                        </div>

                        <div class="row no-gutters mt-4" :class="{ 'form-error': $v.formdata.optIn.$error }">
                            <label class="checkbox-label font-weight-normal"
                                   key="label-input-start-charging"
                                   :class="{'checked': formdata.optIn}"
                                   for="contact-checkbox">
                                <input type="checkbox"
                                       class="checkbox-input"
                                       id="contact-checkbox"
                                       :checked="formdata.optIn"
                                       @change="setData($event.target.checked, 'optIn')">
                                <span class="checkbox-checkmark gmp-icon icon-checkbox"></span>
                                <span class="font-size-small font-reset mr-2">
                                  <router-link class="no-color" :to="{name: 'terms-of-use'}" target="_blank">Ich habe die Nutzungsbedingungen gelesen und stimme diesen zu.</router-link>
                                </span>
                            </label>
                            <div>
                              Die von Ihnen angegebenen Daten verwenden wir ausschließlich zur Bearbeitung Ihrer Anfrage. Weitere Informationen zum Umgang mit Ihren personenbezogenen Daten finden Sie in unserer <router-link class="no-color" :to="{name: 'data-protection'}" target="_blank">Datenschutzerklärung</router-link>.
                            </div>
                        </div>

                        <div class="row no-gutters mt-4">
                            <vue-recaptcha ref="recaptcha"
                                           class="mx-auto"
                                           :sitekey="reCaptcha.apiKey"
                                           size="invisible"
                                           badge="inline"
                                           @verify="recaptchaVerified"
                                           @expired="recaptchaExpired"
                                           @render="recaptchaRendered"></vue-recaptcha>
                        </div>

                        <div class="form-group mt-4" :class="{ 'form-error': $v.formdata.$error }">
                            <div class="form-group-error" v-if="$v.formdata.$invalid">Bitte prüfen Sie Ihre Angaben.
                            </div>
                            <button id="gbm-eval-contact"
                                    @click="handleSubmit($event)"
                                    class="btn btn-primary btn-block font-reset mt-1 font-weight-bold"
                                    :class="{disabled: submitRequested || $v.formdata.optIn.$invalid}">
                                Jetzt Bewertung Erhalten
                            </button>
                            <p class="font-size-smaller text-monospace mt-4">* Pflichtfeld</p>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VueRecaptcha from 'vue-recaptcha';
    import {maxLength, required, helpers, email, numeric, requiredIf} from 'vuelidate/lib/validators'
    import Stepper from "../components/Stepper";
    import AccordeonRow from "../components/AccordeonRow";
    import FormRow from "../components/FormRow";
    import SelectDropdown from "../components/SelectDropdown";
    import {defaultCountryForZip, formdata} from "../models/formdata.model"
    import {getApi} from '../services/getApiData.service'

    const isZip = helpers.regex('isZip', /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/);
    const isName = helpers.regex('isName', /^[a-zA-ZÀ-ÿ ,.'-]+$/);
    const isSalutation = helpers.regex('isSalutation', /^(Herr)|(Frau)|(Divers)+$/);
    const isGermany = (value) => value === defaultCountryForZip;

    export default {
        name: 'contactform',
        components: {VueRecaptcha, AccordeonRow, Stepper, FormRow, SelectDropdown},
        data() {
            return {
                submitRequested: false,
                submitTimer: null,
                submitCount: 0,
                salutationOptions: ['Herr', 'Frau', 'Divers'],
                formdata: formdata,
                reCaptcha: {
                    apiKey: process.env.VUE_APP_RECAPTCHA_KEY,
                    completed: false
                }
            }
        },
        mounted() {
            this.$store.dispatch('handleSubmitCount');
            this.formdata = this.$store.state.savedFormdata;

            this.$store.dispatch('getCountryData');

            if (!this.formdata.country) {
              this.formdata.country = defaultCountryForZip;
            }
        },
        beforeDestroy() {
          this.$store.commit('setFormData', this.formdata);
        },
        validations: {
            formdata: {
                email: {
                    required,
                    email,
                    maxLength: maxLength(50)
                },
                country: {
                    required,
                },
                zip: {
                    required: requiredIf(nestedModel => {
                      return isGermany(nestedModel.country);
                    }),
                    isZip(value, nestedModel) {
                      return !isGermany(nestedModel.country) || isZip(value)
                    },
                    zipAvailable(value, nestedModel) {
                        // don't check availability if not a valid ZIP or empty
                        if (!isZip(value) || value === '' || !isGermany(nestedModel.country)) {return true}

                        // return new Promise((resolve) => {
                        //     setTimeout(() => {
                        //         resolve(false)
                        //     }, 950 + Math.random() * 300)
                        // })

                        return getApi('public/zipCheck/' + value, (response) => {
                            return response === true;
                        });
                    }
                },
                salutation: {
                    isSalutaion: isSalutation
                },
                name: {
                    required,
                    isName: isName,
                    maxLength: maxLength(20)
                },
                lastname: {
                    required,
                    isName: isName,
                    maxLength: maxLength(50)
                },
                firm: {
                    maxLength: maxLength(100)
                },
                tel: {
                    numeric,
                    maxLength: maxLength(30)
                },
                optIn: {
                    isTrue(value) {
                        return value === true;
                    }
                }

            }
        },
        methods: {
            setData(value, type) {
                this.formdata[type] = value;
                this.$v.formdata[type]['$touch']();
            },
            handleSubmit(e) {
                e.preventDefault();
                this.$v.$touch();
                // console.log('submit requested');
                if (!this.$v.$invalid && !this.submitRequested) {
                    // console.log('executing recaptcha');
                    this.$refs.recaptcha.execute();
                    this.submitRequested = true;
                    this.submitCounter = setTimeout(() => {
                        this.submitRequested = false;
                    }, 5000)
                }
            },
            recaptchaVerified(response) {
                // console.log('recaptcha verified', response);
                this.formdata.token = response;
                this.formdata.machinedata = this.$store.state.selectedData;
                const dataToSend = {...this.formdata};

                if (!isGermany(dataToSend.country)) {
                  dataToSend.zip = undefined;
                }

                this.$store.dispatch('sendFormData', dataToSend);
                // console.log('FORM READY', this.formdata);

                clearTimeout(this.submitCounter);
                this.reCaptcha.completed = true;
            },
            recaptchaExpired() {
                // console.log('recaptcha expired');
                this.reCaptcha.completed = false;
            },
            recaptchaRendered() {
                // console.log('recaptcha rendered', id);
            }
        },
        computed: {
            showValueGrafik() {
                return this.isLessThan6 && !this.isSpecialOrOld;
            },
            isLessThan6() {
                return this.$store.state.submitCount < 6
            },
            isSpecialOrOld() {
                return this.$store.state.selectedData.isSpecial ||
                    this.$store.state.selectedData.isOld;
            },
          loadRecaptcha() {
              return this.$store.state.reCaptchaConsentGiven;
          },
          countriesLoading() {
              return this.$store.state.countriesLoading;
          },
          countryOptions() {
            return this.$store.state.countryData;
          },
          countryIsGermany() {
              return isGermany(this.formdata.country);
          }
        }
    }
</script>

<style lang="scss">
    /*.grecaptcha-badge {
        bottom: 10px !important;

        @include media-breakpoint-up(md) {
            bottom: 10vh !important;
        }
    }*/
</style>
