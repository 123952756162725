<template>
    <div class="steps-contact py-4">
        <div class="container">
            <div class="row">
                <div class="col-auto">
                    <img class="" :src="imgUrl" alt="Ansprechpartner">
                </div>
                <div class="col">
                    <p>
                      Wir melden uns bei Ihnen in den nächsten Tagen zu Ihrer Anfrage! Gerne können Sie aber auch jederzeit selbst Kontakt aufnehmen.
                    </p>
                    <steps-contact-address
                            class="d-none d-md-block"
                            :contact-data="contactData"
                            :full-name="fullName"></steps-contact-address>
                </div>
            </div>
            <div class="row mt-2 d-md-none" v-if="contactData.street && contactData.street !== ''">
                <div class="col">
                    <steps-contact-address
                            :contact-data="contactData"
                            :full-name="fullName"></steps-contact-address>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    import contactPlaceholder from '../assets/images/platzhalter-contact.png';
    import StepsContactAddress from "./StepsContactAddress";

    export default {
        name: 'steps-contact',
        components: {StepsContactAddress},
        computed: {
            ...mapState({
                contactData: state => state.contactData
            }),
            fullName() {
                return this.contactData.first_name_de + ' ' + this.contactData.last_name_de;
            },
            imgUrl() {
                if (this.contactData.filePicUrl && this.contactData.filePicUrl !== '') {
                    return this.contactData.filePicUrl;
                }
                return contactPlaceholder;
            }
        }
    }
</script>

<style lang="scss">
    .steps-contact {
        img {
            max-width: 85px !important;
            height: auto;
        }
    }
</style>