import router from '../router.js'

export async function getApi(request, processResponse, supressError) {
    return fetch(request, {
        method: 'GET',
        cache: 'no-cache'
    })
        .then(response => {
            return response.text();
        })
        .then(text => {
            return text ? JSON.parse(text) : null;
        })
        .then(processResponse)
        .catch(e => {
            return processError(e, supressError);
        });
}

export async function postApi(request, data, processResponse, supressError) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json; charset=utf-8');

    return fetch(request, {
        method: 'POST',
        cache: 'no-cache',
        mode: 'same-origin',
        credentials: 'same-origin',
        headers: headers,
        body: JSON.stringify(data)
    })
        .then(response => {
            return response.text();
        })
        .then(text => {
            return text ? JSON.parse(text) : null;
        })
        .then(processResponse)
        .catch(e => {
            return processError(e, supressError);
        });
}

function processError(e, supressError) {
    if (!supressError && !window.__PRERENDER_INJECTED) {
        router.push({name: 'error-page'});
    } else {
        console.error(e);
    }
    return e;
}