<template>
    <!--Component not finished, use it to wrap form rows when completed-->
    <div :class="{ 'form-error': validatorData.$error }">
        <label class="mb-1 mt-3 font-weight-bold">{{ label }}<slot name="inLabel"></slot></label>

        <div class="gmp-icon"
              :class="[
            {['icon-warning-' + rowType]: validatorData.$error},
            {['icon-success-' + rowType]: !validatorData.$invalid && validatorData.$model !== ''}]">
            <slot></slot>
        </div>

        <div class="form-group-error"
             v-for="(error, index) in filteredErrors"
             :key="index"
             v-html="error.text">
        </div>

        <!--<div v-if="errorDataTest.dropdown" class="form-group-error">Bitte wählen Sie eine Option aus.</div>-->
        <!--<div v-if="errorDataTest.required" class="form-group-error">Bitte geben Sie diese Information ein.</div>-->
        <!--<div v-if="errorDataTest.isEmail" class="form-group-error">Bitte geben Sie eine valide E-Mail-Adresse ein.</div>-->
        <!--<div v-if="errorDataTest.length" class="form-group-error">Bitte geben Sie weniger als {{ errorDataTest.maxLength }} Zeichen ein.</div>-->
    </div>
</template>

<script>
    export default {
        name: 'form-row',
        props: {
            validatorData: {
                type: Object,
                required: true
            },
            label: {
                type: String,
                required: true
            },
            errorData: {
                type: Array,
                required: false,
                default: () => []
            },
            rowType: {
                type: String,
                required: false,
                default: 'input',
                validator: value => ['input', 'select'].indexOf(value) !== -1
            },
            errorDataTest: {
                type: Object,
                required: false,
                default: () => {}
            }
        },
        computed: {
            filteredErrors() {
                return this.errorData.filter((error) => error.value)
            }
        }
    }
</script>

<style lang="scss">
    /*TODO: move styles from _forms.scss*/
</style>