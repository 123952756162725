export const specialText = 'Sonstige';

export const valueIsSpecial = value => {
    return value.toLowerCase() === specialText.toLowerCase();
};

export const specialTextmixin = {
    data() {
        return {
            specialText: specialText
        }
    },
    methods: {
        valueIsSpecial: valueIsSpecial
    }
};