<template>
    <div class="advantages-wrapper pt-0 pt-md-6 pt-lg-5">
        <!--<picture class="">-->
            <!--<source srcset="@/assets/images/advantages-front-small.png, @/assets/images/advantages-front-small.png 2x">-->
        <div class="guy-wrapper bg-secondary">
            <img src="@/assets/images/advantages-front-small.png" class="background-guy" alt="Verkaufen Sie Ihre gebrauchte Maschine!">
        <!--</picture>-->
        <div class="container">
            <div class="row">
                <!--<img class="img-fluid d-md-none" src="@/assets/images/headervisual-1200-1x.jpg" alt="">-->
                <picture class="d-md-none">
                    <source srcset="@/assets/images/advantages-back-768_1x.jpg, @/assets/images/advantages-back-768_2x.jpg 2x">

                    <img src="@/assets/images/advantages-back-768_1x.jpg" class="img-fluid" alt="Verkaufen Sie Ihre gebrauchte Maschine!">
                </picture>
                <div class="text-white advantages-overlay col-12 col-md-10 col-lg-12 mr-auto py-5">
                    <h3 class="pb-4 pb-md-5">Ihre Vorteile beim Verkauf über Baggerboerse.de</h3>
                    <div class="row">
                        <div class="col-12 col-lg-4">
                            <ul class="font-weight-light mb-0">
                                <li class="gmp-icon icon-checkmark">100% kostenloser und unverbindlicher Service</li>
                                <li class="gmp-icon icon-checkmark">Schnelle und einfache Erstbewertung online</li>
                                <li class="gmp-icon icon-checkmark">Terminvereinbarung durch Zeppelin</li>
                                <li class="gmp-icon icon-checkmark">
                                    Finale Bewertung vor Ort durch unsere Experten mit langjähriger Erfahrung
                                </li>
                            </ul>
                        </div>
                        <div class="col-12 col-lg-4">
                            <ul class=" font-weight-light pr-4">
                                <li class="gmp-icon icon-checkmark">Persönliche Beratung und professionelle Abwicklung</li>
                                <li class="gmp-icon icon-checkmark">
                                    <!--wrap all text in span to fix flex messing up the link-->
                                    <!--<a class="text-warning" href="#" target="_blank">Zeppelin Niederlassung in Ihrer Nähe</a>-->
                                    Ankauf durch eine Zeppelin Niederlassung in Ihrer Nähe
                                </li>
                                <li class="gmp-icon icon-checkmark">Ankauf unabhängig vom Hersteller</li>
                                <li class="gmp-icon icon-checkmark">Seriöse Zahlungsabwicklung</li>
                            </ul>
                        </div>
                    </div>

                    <button id="gbm-eval-body" class="btn btn-warning font-weight-bold px-5 mt-3" @click="handleClick">Jetzt Bewertung erhalten
                    </button>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'advantages',
        methods: {
            handleClick() {
                this.$store.dispatch('scrollToForm');
            }
        }
    }
</script>

<style lang="scss">
    .advantages-wrapper {
        overflow-x: hidden;
        max-width: 100vw;

        .guy-wrapper{
            position: relative;
            overflow-y: visible;

            @include media-breakpoint-up(md) {
                background-image: url('../assets/images/advantages-back-1200_1x.jpg');
                background-size: cover;
                background-position: right;
            }
        }

        .background-guy {
            position: absolute;
            right: 2vw;
            height: 110%;
            bottom: 0;

            @include media-breakpoint-down(sm) {
                display: none;
            }

            @include media-breakpoint-up(md) {
                right: -20vw;
            }

            @include media-breakpoint-up(lg) {
                right: -7vw;
            }

            @include media-breakpoint-up(xl) {
                right: 2vw;
            }

            @include media-breakpoint-up(xxl) {
                right: 10vw;
            }
        }

        .row {
            position: relative;

            .advantages-overlay {
                /*position: absolute;
                top: 130px;
                left: 0;

                @include media-breakpoint-down(md) {
                    position: static;
                }*/
            }
        }
    }
</style>
