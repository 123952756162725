<template>
    <div class="container mt-6 mb-5">
        <h1>Nutzungs&shy;bedingungen</h1>
        <h5>für die Website www.baggerboerse.de und die mit ihr bereitgestellten Services</h5>

        <div class="mx-auto font-weight-light mt-5">
            <h5>1 Einleitung und Begriffsbestimmungen</h5>
            <p>1.1 Die Zeppelin Baumaschinen GmbH (ZBM) betreibt unter der Webadresse www.baggerboerse.de eine Internetwebsite. Diese soll verkaufswilligen Eigentümern von gebrauchten Baumaschinen durch Bereitstellung von Informationen, insbesondere im Wege einer unentgeltlichen und unverbindlichen Online-Bewertung eine erste Orientierungshilfe in Bezug auf den etwaigen Verkauf ihrer Gebrauchtmaschinen geben. Sämtliche Inhalte, welche ZBM im Wege der Website bereitstellt, namentlich alle Informationen, Datenblätter, Fotos, Bilder, Grafiken, Marken, Kennzeichen, des weiteren alle anderen Bestandteile der Website wie insbesondere Tools, Funktionen, Services, werden im Folgenden kurz Inhalte genannt.</p>

            <h5><br>2 Geltung, Änderung dieser Nutzungsbedingungen</h5>
            <p>2.1 Die Bereitstellung der Website <a class="no-color" href="http://www.baggerboerse.de/" target="_blank">www.baggerboerse.de</a> (im Folgenden kurz Website oder Site) und aller mit der Site angebotenen Inhalte erfolgt ausschließlich aufgrund und nach Maßgabe dieser Nutzungsbedingungen in der jeweils gültigen Fassung.</p>
            <p>2.2 Mit der Nutzung der Website unterwirft sich der Nutzer diesen Nutzungsbedingungen.</p>
            <p>2.3 ZBM steht es frei, die Nutzungsbedingungen jederzeit zu ändern. Etwa geänderte Nutzungsbedingungen werden durch fortgesetzte Nutzung der Website wirksam und verbindlich.</p>

            <h5><br>3 Bereitstellung der Website</h5>
            <p>3.1 ZBM behält sich vor, die Funktionsweise der Website sowie die bereitgestellten Inhalte jederzeit zu ändern, zu ergänzen oder zu kürzen oder die Bereitstellung zeitweise oder sogar endgültig einzustellen, ohne dass daraus der Nutzer Rechtsfolgen ableiten kann.</p>

            <h5><br>4 Inhalte und Links</h5>
            <p>4.1 Die von ZBM mit angemessener Sorgfalt und nach bestem Wissen ausgewählten Inhalte sind nicht für die stets individuellen Anforderungen und Bedürfnisse von Einzelpersonen, sondern für eine Vielzahl von Nutzern zusammengestellt. Die Inhalte können eine individuelle Beratung daher nicht ersetzen. Falls der Nutzer zu Inhalten oder einzelnen Hinweisen auf der Website ergänzende Fragen haben sollte, möge er unbedingt weiterführende Informationen einholen. Sollte der Nutzer auf eine individuelle Beratung verzichten, trägt er alleine Gefahr und Risiko allenfalls ungewollter bzw. seinen Anforderungen nicht gerecht werdender Dispositionen.</p>
            <p>4.2 Jegliche technischen Daten, etwa Informationen über Spezifikationen, Ausstattungen, Anbauteile von Baumaschinen, aber auch Abbildungen und dgl. verstehen sich als bloß beispielhaft.</p>
            <p>4.3 Falls die Website auch Links zu Webseiten Dritter enthalten sollte, hat ZBM auf deren Inhalt keinen Einfluss. ZBM macht sich diese Inhalte in keiner Weise zu eigen und übernimmt für sie keinerlei Haftung. Für die Inhalte von verlinkten Seiten ist stets der jeweilige Anbieter alleine verantwortlich. Sollten Inhalte von verlinkten Seiten rechtswidrig sein oder werden, war dies für ZBM nicht erkennbar. ZBM distanziert sich ausdrücklich von allen auf fremden Internetseiten gemachten Äußerungen und sämtlichen sonstigen Inhalten, welche gegen gesetzliche oder vertragliche Verbote oder Beschränkungen verstoßen.</p>

            <h5><br>5 Verwertungs- und Nutzungsrechte</h5>
            <p>5.1 Sämtliche Rechte an den Inhalten verbleiben uneingeschränkt beim jeweiligen Berechtigten, demnach entweder bei ZBM oder sonst berechtigten Dritten. Alle Firmenzeichen, Logos, Texte, Bilder und sonstige Daten unterliegen dem Urheberrecht und sind geschützt. In den Inhalten der Website enthaltene Rechtevorbehalte wie insbesondere Urheberrechtshinweise, Markenbezeichnungen und Ähnliches hat der Nutzer zu respektieren.</p>
            <p>5.2 Ohne die vorherige schriftliche Zustimmung seitens ZBM dürfen die Inhalte weder als Ganzes noch in Teilen bearbeitet oder sonst verändert, verwendet, gespeichert, kopiert, vervielfältigt, verbreitet oder Dritten zugänglich gemacht werden. Die über den Nutzungsumfang in Punkt 7. vorgesehenen Bestimmungen bleiben unberührt.</p>
            <p>5.3 Der Nutzer wird ausdrücklich darauf hingewiesen, dass es sich beiden folgenden Eigennamen und/oder Bezeichnungen um geschützte Marken bzw.(Unternehmens-)Kennzeichen handelt: Graf Zeppelin, Zeppelin, Z Zeppelin, zeppelin university, Deutsche Zeppelin Reederei, überdies Zeppelin Museum Friedrichshafen Technik und Kunst, ZEPPELIN ecoparts, des Weiteren SpeedFlow, MVS, MVS Mietpartner, CAT.</p>

            <h5><br>6 Online-Bewertung</h5>
            <p>6.1 Für jeden Nutzer, der eine ihm gehörende gebrauchte Baumaschine veräußern möchte, hält die Website ein Tool bereit, welches anhand der vom Nutzer einzugebenden Maschinendaten eine Bewertung online durchführt und dem Nutzer, falls gewünscht, das Bewertungsergebnis per E-Mail bekannt gibt. Zu den für die Bewertung relevanten Daten zählen etwa Hersteller, Kategorie der Baumaschine, Maschinentyp, Baujahr und zurückgelegte Betriebsstunden.</p>
            <p>6.2 Die Online-Bewertung, die einen altersentsprechend mittleren Erhaltungszustand unterstellt, erfolgt auf Grundlage von statistischen Durchschnittswerten. Sie soll dem Nutzer als grobe erste Orientierungshilfe dienen, vermag allerdings individuelle Begutachtung zur Ermittlung des in Betracht kommenden konkreten Kaufpreises nicht zu ersetzen, müssen doch sämtliche wertbestimmenden Umstände stets einzelfallbezogen hinterfragt werden, namentlich Zustand, diesbezügliche Marktsituation, Zubehör, etwaige Schäden. Dazu ist jedoch eine Begutachtung der Baumaschine durch einen Experten notwendig. Hierfür wird sich nach Übermittlung der Online Bewertung ein Gebrauchtmaschinen-Experte der ZBM per Mail oder, sofern Sie im Formular eine Telefonnummer hierfür hinterlegt haben, per Telefon beim Nutzer melden und dem Nutzer die weiteren Möglichkeiten einer Vorgehensweise aufzeigen.</p>
            <p>6.3 Die Online-Bewertung ist nur zur Orientierungshilfe bestimmt. Sie ist unverbindlich und stellt weder ein Verkaufsangebot des Nutzers noch ein Kaufangebot der ZBM dar. Vielmehr erteilt die ZBM verbindliche Auskünfte, Beratungen, Empfehlungen oder Erklärungen ausschließlich nur im Rahmen individueller Kommunikation.</p>

            <h5><br>7 Nutzungsumfang</h5>
            <p>7.1 Die Nutzung der Site ist ausschließlich für individuelle und nichtkommerzielle Zwecke erlaubt. Jede gewerbliche oder kommerzielle Nutzung, Vervielfältigung, Verwertung oder Zugänglichmachung der Inhalte für Dritte, ganz oder auch nur auszugsweise, ist ausdrücklich untersagt, vor allem dürfen die durch Online-Bewertung gewonnenen Daten weder vollständig noch teilweise oder auszugsweise zum Aufbau einer eigenen Datenbank oder in welcher Form immer zur Auskunftserteilung gegenüber Dritten verwendet werden.</p>
            <p>7.2 Keinesfalls darf der Nutzer Teile der Website, der mit ihr bereitgestellten Inhalte (Punkt 1.1) oder Services systematisch extrahieren oder wiederverwenden. Insbesondere dürfen Nutzer kein Data Mining, keine Robots, Extraktionssoftware oder ähnliche Datensammelprogramme einsetzen, um Teile der Website oder deren Inhalte zur Wiederverwendung zu extrahieren.</p>
            <p>7.3 Eine Archivierung darf der Nutzer nur zu eigenen Zwecken anlegen.</p>

            <h5><br>8 Unentgeltlichkeit</h5>
            <p>8.1 Die nach Maßgabe dieser Nutzungsbedingungen vorgesehene Nutzung der Site <a class="no-color" href="http://www.baggerboerse.de/" target="_blank">www.baggerboerse.de</a> bzw. der bereitgestellten Informationen und Services ist unentgeltlich.</p>

            <h5><br>9 Verbot von missbräuchlicher Nutzung</h5>
            <p>9.1 Der Nutzer darf die Inhalte, Informationen und Services nur bestimmungsgemäß verwenden.</p>
            <p>9.2 Jedes Auskundschaften von Systemfunktionen und/oder von Daten, welche nicht für den Abruf durch ihn bestimmt sind, ist dem Nutzer untersagt. Dasselbe gilt für jedwede Aktivitäten, die darauf gerichtet sind, die Website und/oder die bereitgestellten Services funktionsuntauglich zu machen oder deren Nutzung zu erschweren.</p>
            <p>9.3 Für den Fall eines Verdachts auf missbräuchliche Nutzung behält sich die ZBM das Recht vor, die Vorgänge zu untersuchen und entsprechende Vorkehrungen zu treffen. Der Nutzer haftet für alle Schäden, welche er durch missbräuchliche Nutzung verursacht.</p>

            <h5><br>10 Gewährleistungs-, Haftungsausschluss</h5>
            <p>10.1 Bei der Digitalisierung von Daten können manchmal Fehler auftreten. Durch die Verwendung unterschiedlicher Internetbrowser, darüber hinaus bedingt auch durch individuelle Softwareeinstellungen kann es bei der Darstellung der Inhalte zu Abweichungen kommen.</p>
            <p>10.2 ZBM ist um laufende Wartung der Website und der mit ihr bereitgestellten Inhalte bemüht. Dennoch kann ZBM für deren Aktualität, inhaltliche Richtigkeit, Vollständigkeit, Fehlerfreiheit und laufende Verfügbarkeit oder für eine bestimmte Verwendbarkeit und/oder Verwertbarkeit der Inhalte (Punkt 1.1) keine Gewähr übernehmen. Insbesondere können durch die Nutzung der Website oder die Verwendung deren Inhalte keinerlei Rechtsansprüche oder Forderungen welcher Art immer gegen ZBM begründet werden.</p>
            <p>10.3 Gewährleistung sowie Haftung (Schadenersatz) werden seitens ZBM aufgrund der Unentgeltlichkeit der Inhalte, Informationen und Services, soweit gesetzlich zulässig, ausgeschlossen. Für den Fall, dass ZBM aufgrund zwingenden Rechts unabdingbar ausnahmsweise haften muss, gilt im Übrigen Folgendes: ZBM haftet ausschließlich nur für Vorsatz, ferner, falls der Nutzer den Beweis erbringt, für krass grobe Fahrlässigkeit, nicht jedoch für geringeres Verschulden. Sollte ZBM ausnahmsweise haften müssen, wird für reine Vermögensschäden, Folgeschäden, mittelbare Schäden, entgangenen Gewinn, unterbliebene Einsparungen, immaterielle Schäden und für Schäden aus etwaiger Anspruchserhebung durch Dritte in keinem Fall gehaftet.</p>

            <h5><br>11 Datenschutz, E-Mail-Korrespondenz</h5>
            <p>11.1 Insoweit der Nutzer zum Zweck des beabsichtigten Verkaufs einer gebrauchten Baumaschine eine Online-Bewertung anfragt und dazu personenbezogene Daten bekannt gibt, sind diese zur Durchführung vorvertraglicher Maßnahmen erforderlich. Die Daten erhebt und speichert, verarbeitet und verwendet ZBM demnach gemäß den Bestimmungen des Art. 6 Abs. 1 lit. b) der EU-Datenschutz-Grundverordnung(DSGVO). Gibt der Nutzer ihn betreffende personenbezogene Daten bekannt, müssen diese der Wahrheit entsprechen.</p>
            <p>11.2 Durch Genehmigung der Nutzungsbedingungen bestätigt der Nutzer über die ihm datenschutzrechtlich nach den Bestimmungen der Art. 12 ff DSGVO zustehenden Rechte informiert zu sein, und zwar Auskunftsrecht, die Rechte auf Berichtigung, Löschung, Einschränkung der Verarbeitung, das Recht auf Datenübertragbarkeit, das Widerspruchsrecht sowie das für den Fall unrechtmäßiger Datenverwendung zustehende Beschwerderecht.</p>
            <p>11.3 Detaillierte Informationen in Bezug auf den Schutz personenbezogener Daten hat die ZBM dem Nutzer ferner im Wege der auf der Sitebereitgestellten Datenschutzerklärung gesondert erteilt.</p>
            <p>11.4 ZBM ist des Weiteren berechtigt, die gesamte E-Mail-Korrespondenz mit dem Nutzer in nicht verschlüsselter Form abzuwickeln. Durch Genehmigung dieser Nutzungsbedingungen bestätigt der Nutzer, in Kenntnis der damit verbunden Risiken (insbesondere Zugang, Geheimhaltung, ferner Verlust oder Veränderung von Nachrichten im Zuge der Übermittlung) zu sein sowie in Kenntnis dieser Risiken zuzustimmen, dass die E-Mail-Korrespondenz nicht in verschlüsselter Form abgewickelt wird.</p>

            <h5><br>12 Sonstige Bestimmungen</h5>
            <p>12.1 Von diesen Nutzungsbedingungen abweichende Vereinbarungen, Änderungen oder Ergänzungen sind nur wirksam, wenn sie schriftlich erfolgen.</p>
            <p>12.2 Sollten einzelne Bestimmungen dieser Nutzungsbedingungen ungültig sein oder werden, oder sollte sich eine Regelungslücke herausstellen, so werden hierdurch alle übrigen Bestimmungen nicht berührt. An die Stelle der ungültigen tritt jene wirksame Bestimmung, welche dem Zweck der ungültigen Bestimmung möglichst nahekommt. Eine etwaige Regelungslücke ist hingegen nach Maßgabe des Zwecks dieser Nutzungsbedingungen zu schließen.</p>
            <p>12.3 Rechtswahl; Gerichtsstand</p>
            <p>12.4 Für die Rechtsbeziehungen zwischen dem Nutzer und ZBM gilt das Recht der Bundesrepublik Deutschland. Nationaler und internationaler Gerichtsstand für alle Streitigkeiten ist München (Landgerichtsbezirk München I).</p>

            <p>&nbsp;</p>
            <p>Stand Mai 2020</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'terms-of-use'
    }
</script>