<template>
    <div class="container mt-6 mb-5 overflow-hidden">
        <h1>Datenschutzerklärung</h1>

        <a class="btn btn-warning text-uppercase font-weight-bold px-5 mt-3" href="/downloads/Hinweis_Datenschutz_Baggerboerse_10_22.pdf" target="_blank">Datenschutzerklärung herunterladen</a>

        <div class="mx-auto font-weight-light mt-5">
            <p>Wir freuen uns über Ihr Interesse an unserer Webseite <router-link class="no-color" :to="{name: 'home'}">https://www.baggerboerse.de/</router-link> (“Webseite“) und möchten Ihren Besuch so angenehm wie möglich gestalten. Betreiber dieser Webseite und Verantwortlicher für die Verarbeitung Ihrer personenbezogenen Daten über diese Webseite ist die<br>
                Zeppelin Baumaschinen GmbH<br>
                Graf-Zeppelin-Platz 1<br>
                85748 Garching bei München, Deutschland<br>
                Telefon: +49 (0) 89 32 00 00<br>
                Email: <a href="mailto:zeppelin-cat@zeppelin.com" class="no-color">zeppelin-cat@zeppelin.com</a>.</p>
            <p>Neben einer komfortablen und effizienten Bedienbarkeit steht für uns der Schutz Ihrer personenbezogenen Daten im Vordergrund. Der Schutz Ihrer Privatsphäre bei der Verarbeitung personenbezogener Daten ist uns ein wichtiges Anliegen, das wir in allen unseren Geschäftsprozessen berücksichtigen.</p>
            <p>Wir verarbeiten personenbezogene Daten, die beim Besuch unserer Webseite erhoben werden, daher stets gemäß den jeweils einschlägigen datenschutzrechtlichen Bestimmungen.</p>
            <p>Mit dieser Datenschutzerklärung informieren wir Sie darüber, welche personenbezogenen Daten von Ihnen erhoben und gespeichert werden, wenn Sie unsere Webseite besuchen bzw. unsere über die Webseite angebotenen Dienste nutzen. Außerdem erhalten Sie Informationen dazu, wie und auf welcher Rechtsgrundlage Ihre Daten verwendet werden, welche Rechte Ihnen im Hinblick auf die Verwendung Ihrer Daten zustehen und welche Kontaktmöglichkeiten Ihnen zur Verfügung stehen.</p>


            <p><br><strong>1. Verarbeitung personenbezogener Daten und Zwecke der Verarbeitung</strong></p>

            <p class="mb-0"><strong>1.1 Welche Daten werden von uns erhoben?</strong></p>
            <p>Im Detail werden folgende personenbezogene Daten erhoben:</p>

            <p class="mb-0"><strong>1.1.1 Beim Besuch unserer Webseite</strong></p>
            <p>Sie können unsere Webseite besuchen, ohne Angaben zu Ihrer Identität preiszugeben. Beim Aufruf unserer Webseite werden jedoch durch den von Ihnen benutzten Browser automatisch Informationen an den Server unserer Webseite gesendet und temporär in einem Log-File gespeichert. Ihre Identität wird durch diese Informationen nicht preisgegeben. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung nach sechs Monaten gespeichert:</p>
            <ul class="default-list">
                <li>die IP-Adresse des anfragenden Rechners,</li>
                <li>das Datum und die Uhrzeit des Zugriffs,</li>
                <li>der Name und die URL der abgerufenen Datei,</li>
                <li>der von Ihnen verwendete Browser und ggfs. das Betriebssystem Ihres Rechners,</li>
                <li>Webseiten, von denen das System des Nutzers auf unsere Internetseite gelangt (Referrer),</li>
                <li>Webseiten, die vom System des Nutzers über unsere Webseite aufgerufen werden.</li>
            </ul>
            <p>Die Erhebung und Verarbeitung dieser Daten erfolgt zu dem Zweck, die Nutzung unserer Webseite zu ermöglichen (Verbindungsaufbau). Diese Daten werden ausschließlich aus technischen Gründen gespeichert und werden zu keinem Zeitpunkt einer bestimmten Person zugeordnet. Außerdem dient die Erhebung der genannten Daten dem Zweck, die Systemsicherheit und -stabilität zu gewährleisten, sowie zur technischen Administration der Netzinfrastruktur. Rechtsgrundlage ist insofern Art. 6 Abs. 1 lit. f DS-GVO. Unser berechtigtes Interesse an der Datenverarbeitung liegt dabei darin, das ordnungsgemäße Funktionieren unserer Webseite sowie der darüber abgewickelten Kommunikation sicherzustellen. Rückschlüsse auf Sie als Person ziehen wir in diesem Zusammenhang nicht.</p>
            <p>Des Weiteren verwenden wir für unsere Webseite Cookies sowie Webanalysedienste (siehe unter Ziffer 2.).</p>

            <p class="mb-0"><strong>1.1.2 Formular zur Bewertung von Gebrauchtmaschinen</strong></p>
            <p>Wir bieten Ihnen die Möglichkeit, Ihre Gebrauchtmaschine durch uns bewerten zu lassen. Dabei sind die folgenden Angaben verpflichtend, um Ihre Anfrage zu beantworten:</p>
            <ul class="default-list">
                <li>Maschinendaten,</li>
                <li>Vor- und Nachname,</li>
                <li>eine gültige E-Mail-Adresse: Ihre E-Mail-Adresse benötigen wir für die Zusendung der Bewertung und für die Kontaktaufnahme unseres Gebrauchtmaschinen-Experten für eine Terminvereinbarung mit Ihnen,</li>
                <li>Firmenname zur Angebotserstellung,</li>
                <li>PLZ: Ihre PLZ benötigen wir für die korrekte Zuweisung Ihrer Anfrage an einen unserer regionalen Gebrauchtmaschinen-Experten.</li>
            </ul>
            <p>Zusätzlich können Sie optional folgende Angaben tätigen</p>
            <ul class="default-list">
                <li>Anrede, damit wir Sie richtig anreden können,</li>
                <li>eine gültige Telefonnummer beim Rückrufwunsch.</li>
            </ul>
            <p>Diese Angaben speichern wir für Nachweiszecke über einen Zeitraum von bis zu 10 Jahren, sofern es zum verbindlichen Angebot, bzw. zu einem Vertragsabschluss kommt. Bleibt es bei einem unverbindlichen Angebot, werden Ihre Daten nach 2 Jahren gelöscht.</p>
            <p>Zweck der Erhebung der im hierfür vorgesehen Formular angegebenen Daten ist es, den Anfragenden zu identifizieren und die Anfrage sachgerecht und auf dem gewünschten Kommunikationsweg beantworten zu können. Hierin besteht auch unser berechtigtes Interesse. Rechtsgrundlage zur Datenverarbeitung ist insoweit Art. 6 Abs. 1 lit. f DS-GVO.</p>

            <p class="mb-0"><strong>1.1.3 Bei der werblichen Nutzung Ihrer Daten</strong></p>
            <p>Zeppelin hat grundsätzlich ein berechtigtes Interesse daran, die von Ihnen erhobenen Daten (z.B. im Rahmen der Eingehung eines Vertragsverhältnisses oder durch die Erstellung eines Kundenkontos) zu werblichen Zwecken zu nutzen. Hierfür verwenden wir Ihren Vornamen, Nachnamen, ggf. das Unternehmen sowie die Postadresse, um Ihnen über einen Zeitraum von 2 Jahren postalische Werbung zuzusenden. Sofern Zeppelin unter Einhaltung der gesetzlichen weitere personenbezogene Daten zu Marketingzwecken erhoben hat, werden diese ggf. hinzugespeichert (z.B. gekaufte Produkte oder Services). Hierdurch ist Zeppelin in der Lage Ihnen Werbung zukommen zu lassen, die Ihren Bedürfnissen möglichst nahekommt. Rechtsgrundlage hierfür ist das berechtigte Interesse von Zeppelin gem. Art. 6 Abs. 1 lit. f DS-GVO an der Bewerbung seiner Produkte und Services. Sie können der Nutzung Ihrer personenbezogenen Daten zu vorgenannten Werbezwecken jederzeit mit Wirkung für die Zukunft widersprechen. Hierfür können Sie die in dieser Datenschutzerklärung genannten Kontaktdaten von Zeppelin (Post / E-Mail / Telefon) nutzen.</p>


            <p><br><strong>2. Cookies</strong></p>

            <p class="mb-0"><strong>2.1 OneTrust Cookie</strong></p>
            <p>Mit Hilfe des OneTrust Cookie Compliance Tools können Sie in gesetzeskonformer Weise in das Speichern von Cookies einwilligen sowie den Widerruf der Einwilligung erklären. Zugleich wird Ihre Einwilligung dokumentiert und das Setzen der Cookies technisch gesteuert. Dafür werden Cookies verwendet, die Ihre Cookie Einstellungen auf unseren Webseiten speichern. So können Ihre Cookie Einstellungen bei einem weiteren Besuch unserer Plattformen beibehalten werden, solange Sie die Cookies nicht vorher löschen. Ihre Einstellungen können Sie jederzeit anpassen.</p>
            <p>Darüber hinaus verwenden wir den OneTrust Consent Manager, mit deren Hilfe Sie Einwilligungen auf unserer Webseite abgeben und im sog. Preference Center jederzeit verwalten. Zugleich werden Ihre Einwilligungen gesetzeskonform dokumentiert.</p>
            <p>Rechtsgrundlage für die hier beschriebene Verarbeitung personenbezogener Daten ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse besteht darin, gesetzlich erforderliche Einwilligungen automatisiert und in gesetzeskonformer Weise dokumentiert einzuholen.</p>
            <p>Wir setzen auf unserer Webseite Cookies ein. Hierbei handelt es sich um kleine Dateien, die Ihr Browser automatisch erstellt und die auf Ihrem Endgerät (PC, Laptop, Tablet, Smartphone, o.ä.) gespeichert werden, wenn Sie unsere Webseite besuchen.</p>
            <p>Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres Angebots für Sie angenehmer zu gestalten. So setzen wir sogenannte Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten unserer Webseite bereits besucht haben oder Sie sich bereits in Ihrem Kundenkonto angemeldet haben. Diese werden nach Verlassen unserer Seite automatisch gelöscht.</p>
            <p>Sofern Sie uns hierzu Ihre Einwilligung erteilt haben, setzen wir ebenfalls zum Zweck der Benutzerfreundlichkeit temporäre Cookies ein, die für einen bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen Sie unsere Seite erneut, um unsere Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits bei uns waren und welche Eingaben und Einstellungen sie getätigt haben, um diese nicht noch einmal eingeben zu müssen. Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DS-GVO. Ihre Einwilligung können Sie jederzeit in unseren Cookie-Einstellungen widerrufen.</p>
            <p>Zum anderen setzen wir Cookies ein, um die Nutzung unserer Webseite statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes für Sie auszuwerten sowie um speziell auf Sie zugeschnittene Informationen einzublenden. Diese Cookies ermöglichen es uns, bei einem erneuten Besuch unserer Seite automatisch zu erkennen, dass Sie bereits bei uns waren. Diese Cookies werden nach einer jeweils definierten Zeit automatisch gelöscht.</p>
            <p>Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch so konfigurieren, dass keine Cookies auf Ihrem Computer gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer Cookie angelegt wird. Die vollständige Deaktivierung von Cookies kann jedoch dazu führen, dass Sie nicht alle Funktionen unserer Webseite nutzen können.</p>

            <p class="mb-0"><strong>2.2 Google Analytics</strong></p>
            <p>Zum Zwecke der bedarfsgerechten Gestaltung und fortlaufenden Optimierung unserer Seiten nutzen wir Google Analytics, ein Webanalysedienst der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland (<a href="https://www.google.de/contact/impressum.html" class="no-color" target="_blank">https://www.google.de/contact/impressum.html</a>). In diesem Zusammenhang werden pseudonymisierte Nutzungsprofile erstellt und Cookies verwendet. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Webseite wie</p>
            <ul class="default-list">
                <li>Browser-Typ/-Version,</li>
                <li>verwendetes Betriebssystem,</li>
                <li>Referrer-URL (die zuvor besuchte Seite),</li>
                <li>Hostname des zugreifenden Rechners (IP-Adresse),</li>
                <li>Uhrzeit der Serveranfrage,</li>
            </ul>
            <p>werden an einen Server von Google in den USA übertragen und dort gespeichert. Die Informationen werden verwendet, um die Nutzung der Webseite auszuwerten. Auch werden diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist. Es wird in keinem Fall Ihre IP-Adresse mit anderen Daten von Google zusammengeführt. Die IP-Adressen werden anonymisiert, so dass eine Zuordnung nicht möglich ist (IP-Masking).</p>
            <p>Die Übermittlung von personenbezogenen Daten in die USA erfolgt nur nach Ihrer vorherigen ausdrücklichen Einwilligung. Rechtsgrundlage für die Speicherung des Cookies und die weitere Auswertung der Daten über einen Zeitraum von 12 Monaten ist die erteilte Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DS-GVO). Sie können Ihre Einwilligung jederzeit in den Cookie-Einstellungen widerrufen.</p>

            <p class="mb-0"><strong>2.3 Google Retargeting/Remarketing</strong></p>
            <p>Wir verwenden auf dieser Website die Remarketing-Funktion oder „Ähnliche Zielgruppen“-Funktion der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland (<a href="https://www.google.de/contact/impressum.html" class="no-color" target="_blank">https://www.google.de/contact/impressum.html</a>). Diese Funktion dient dazu, Ihnen als Besuchern unserer Website im Rahmen des Google-Werbenetzwerks interessenbezogene Werbeanzeigen zu präsentieren. Der Browser des Websitebesuchers speichert sog. „Cookies“, kleine Textdateien, auf Ihrem Computer die es ermöglichen, Sie wiederzuerkennen, wenn Sie Webseiten aufrufen, die dem Werbenetzwerk von Google angehören. Auf diesen Seiten können Ihnen dann Werbeanzeigen präsentiert werden, die sich auf Inhalte beziehen, die Sie zuvor auf Websites aufgerufen haben, die die Remarketing Funktion von Google verwenden. Eine Zusammenführung der im Rahmen des Remarketings erhobenen Daten mit Ihren personenbezogenen Daten, die ggf. von Google gespeichert werden, findet durch Google laut eigenen Aussagen nicht statt. Insbesondere wird laut Google eine Pseudonymisierung beim Remarketing eingesetzt. Ihre Daten werden seitens Googles auch in den USA verarbeitet. Die Übermittlung Ihrer Daten in die USA erfolgt erst nach Ihrer aus drücklichen Einwilligung.</p>
            <p>Die Verwendung der Remarketing-Funktion oder „Ähnliche Zielgruppen“-Funktion erfolgt auf Grundlage der von Ihnen erteilten Einwilligung. Rechtsgrundlage ist Art. 6 Abs. 1 lit. a DS-GVO. Ihre Einwilligung können Sie jederzeit in den Cookie-Einstellungen widerrufen.</p>

            <p class="mb-0"><strong>2.4 Verwendung von Google Adwords Conversion-Tracking</strong></p>
            <p>Wir nutzen das Angebot von Google Adwords – ein weiteres Angebot der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, um mit Hilfe von Werbemitteln (sogenannten Google Adwords) auf externen Webseiten auf unsere attraktiven Angebote aufmerksam zu machen. Diese Werbemittel werden durch Google über sogenannte „Ad Server“ ausgeliefert. Dabei kommen Ad Server Cookies zum Einsatz, die die Auswertung von Performance-Parametern wie z.B. Ad Impressions, Clicks sowie Conversions ermöglichen. So können wir in Relation zu den Daten der Werbekampagnen ermitteln, wie erfolgreich die einzelnen Werbemaßnahmen sind. Sofern Sie über eine Google-Anzeige auf unsere Webseite gelangen, wird von Google Adwords ein Cookie in ihrem PC gespeichert. Diese Cookies verlieren in der Regel nach 30 Tagen ihre Gültigkeit und sollen nicht dazu dienen, Sie persönlich zu identifizieren. Zu diesem Cookie werden in der Regel folgende Analyse-Werte gespeichert, wobei die Daten von Google auch in den USA verarbeitet werden:</p>
            <ul class="default-list">
                <li>Unique Cookie ID</li>
                <li>Anzahl Ad Impressions pro Platzierung (Frequency)</li>
                <li>Letzte Impression (Relevant für Post-View-Conversions)</li>
                <li>OptOut-Information (Markierung, dass der Nutzer nicht mehr angesprochen werden möchte)</li>
            </ul>
            <p>Diese Cookies ermöglichen Google, Ihren Internetbrowser wiederzuerkennen. Sofern ein Nutzer bestimmte Seiten der Webseite eines Adwords-Kunden besucht und das auf seinem Computer gespeicherte Cookie noch nicht abgelaufen ist, können Google und der Kunde erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde. Jedem Adwords-Kunden wird ein anderes Cookie zugeordnet. Cookies können somit nicht über die Webseiten von Adwords-Kunden nachverfolgt werden. Wir selbst erheben und verarbeiten in den genannten Werbemaßnahmen keine personenbezogenen Daten. Wir erhalten von Google lediglich statistische Auswertungen zur Verfügung gestellt. Anhand dieser Auswertungen können wir erkennen, welche der eingesetzten Werbemaßnahmen besonders effektiv sind. Weitergehende Daten aus dem Einsatz der Werbemittel erhalten wir nicht, insbesondere können wir die Nutzer nicht anhand dieser Informationen identifizieren. Eine Übermittlung Ihrer Daten in die USA erfolgt erst nach Ihrer ausdrücklichen Einwilligung. Rechtsgrundlage für die Speicherung des Cookies durch Google ist die erteilte Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DS-GVO). Sie können Ihre Einwilligung jederzeit in den Cookie-Einstellungen widerrufen.</p>
            <p>Weiterführende Informationen sowie die Datenschutzerklärung von Google finden Sie unter: <a href="https://www.google.de/policies/privacy/" class="no-color" target="_blank">https://www.google.de/policies/privacy/</a>.</p>

            <p class="mb-0"><strong>2.5 Google reCAPTCHA</strong></p>
            <p>Wir binden auf unserer Webseite die Funktion zur Erkennung von Bots, z.B. bei Eingaben in Onlineformularen („reCAPTCHA“) des Anbieters Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, ein. Mit Hilfe von Google reCAPTCHA soll verhindert werden, dass Dateneingaben auf unserer Webseite (z.B. in einem Kontaktformular) missbräuchlich durch automatisierte Programme anstatt durch Menschen erfolgen. Hierfür analysiert reCAPTCHA das Verhalten der Webseitenbesucher und sendet die zur Analyse notwendigen Informationen (u.a. IP-Adresse, Mausbewegungen, Verweildauer) an Google.</p>
            <p>Die Datenverarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DS-GVO. Unser berechtigtes Interesse liegt darin, dass wir unsere Webseiten vor missbräuchlichem Ausspähen und SPAM schützen wollen.</p>
            <p>Weitere Informationen zu Google reCaptcha finden Sie unter folgendem Link <a href="https://www.google.com/recaptcha/about/" class="no-color" target="_blank">https://www.google.com/recaptcha/about/</a> sowie in der Datenschutzerklärung von Google unter folgendem Link <a href="https://www.google.com/policies/privacy/" class="no-color" target="_blank">https://www.google.com/policies/privacy/</a>.</p>

            <p class="mb-0"><strong>2.6 Google Tag Manager</strong></p>
            <p>Auf dieser Website wird der Google Tag Manager, eine Lösung der Google Ireland Limited (Gordon House, Barrow St, Dublin 4, Irland), eingesetzt. Durch den Google Tag Manager können verschiedene Codes und Dienste verwaltet und vereinfacht auf der Webseite eingebunden werden. Bei dem Google Tag Manager handelt es sich um eine Cookielose Domain, die eine Übertragung der IP-Adresse an Google erfordert und für die Auslösung anderer Tags sorgt, die unter Umständen Daten erfassen. Der Google Tag Manager greift nicht auf diese Daten zu. Sofern auf Domain- oder Cookie-Ebene eine Deaktivierung von dem Nutzer vorgenommen wurde, bleibt diese für alle Tracking-Tags bestehen, die mit Google Tag Manager implementiert werden. Ihre IP-Adresse kann hierbei von Google auch in den USA verarbeitet werden. Zur Sicherstellung eines angemessenen Datenschutzniveaus haben wir mit Google die sog. Standardvertragsklauseln abgeschlossen.</p>
            <p>Rechtsgrundlage für die hier beschriebene Verarbeitung personenbezogener Daten ist Art. 6 Abs. 1 lit. f) DS-GVO.</p>


            <p><br><strong>3. Datensicherheit</strong></p>

            <p>Alle von Ihnen persönlich übermittelten Daten, einschließlich Ihrer Zahlungsdaten, werden mit dem allgemein üblichen und sicheren Standard SSL (Secure Socket Layer) übertragen. SSL ist ein sicherer und erprobter Standard, der z.B. auch beim Onlinebanking Verwendung findet.</p>
            <p>Sie erkennen eine sichere SSL-Verbindung unter anderem an dem angehängten s am http (also https://...) in der Adressleiste Ihres Browsers oder am Schloss-Symbol im unteren Bereich Ihres Browsers.</p>
            <p>Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre bei uns gespeicherten personenbezogenen Daten gegen Vernichtung, Verlust, Veränderung oder vor einer unbefugten Offenlegung bzw. vor einem unbefugten Zugang zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.</p>


            <p><br><strong>4. Betroffenenrechte</strong></p>

            <p>Als Betroffener im Sinne der DS-GVO stehen Ihnen die nachfolgend beschriebenen Rechte zu. Zur Geltendmachung dieser Rechte wenden Sie sich bitte wie folgt an uns:</p>
            <p><a href="mailto:datenschutz@zeppelin.com" class="no-color">datenschutz@zeppelin.com</a> oder schriftlich an die oben genannte Adresse der Gesellschaft</p>

            <p class="mb-0"><strong>4.1 Auskunftsrecht</strong></p>
            <p>Sie können gem. Art. 15 DS-GVO Auskunft über Ihre personenbezogenen Daten verlangen, die wir verarbeiten.</p>

            <p class="mb-0"><strong>4.2 Recht auf Berichtigung</strong></p>
            <p>Sollten die Sie betreffenden Angaben nicht (mehr) zutreffend sein, können Sie nach Art. 16 DS-GVO eine Berichtigung verlangen. Sollten Ihre Daten unvollständig sein, können Sie eine Vervollständigung verlangen.</p>

            <p class="mb-0"><strong>4.3 Recht auf Einschränkung der Bearbeitung</strong></p>
            <p>Sie haben nach Art. 18 DS-GVO das Recht, eine Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</p>

            <p class="mb-0"><strong>4.4 Recht auf Löschung</strong></p>
            <p>Sie können nach Art. 17 DS-GVO die Löschung Ihrer personenbezogenen Daten verlangen.</p>

            <p class="mb-0"><strong>4.5 Recht auf Datenübertragbarkeit</strong></p>
            <p>Gem. Art. 20 DS-GVO haben Sie das Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie in den Grenzen des Art. 20 Abs. 1 DS-GVO das Recht diese Daten durch uns an einen von Ihnen benannten Dritten übermitteln zu lassen.</p>

            <p class="mb-0"><strong>4.6 Widerspruchsrecht</strong></p>
            <p>Sie können einer Verarbeitung von personenbezogenen Daten für Zwecke der Werbung einschließlich einer Analyse von Kundendaten für Werbezwecke jederzeit ohne Angabe von Gründen widersprechen.</p>
            <p>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit einer Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DS-GVO erfolgt, nach Maßgabe des Art. 21 DS-GVO zu widersprechen. Wir werden die Verarbeitung Ihrer personenbezogenen Daten einstellen, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>

            <p class="mb-0"><strong>4.7 Widerruf von Einwilligungen</strong></p>
            <p>Außerdem haben Sie jederzeit das Recht, von Ihnen erteilte datenschutzrechtliche Einwilligungserklärungen mit Wirkung für die Zukunft zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.</p>


            <p><br><strong>5. Automatisierte Einzelfallentscheidungen oder Maßnahmen zum Profiling</strong></p>

            <p>Wir nutzen keine automatisierten Verarbeitungsprozesse zur Herbeiführung einer Entscheidung – einschließlich Profiling.</p>


            <p><br><strong>6. Recht auf Beschwerde bei einer Aufsichtsbehörde</strong></p>

            <p>Wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten gegen Datenschutzrecht verstößt, haben Sie nach Ar. 77 Abs. 1 DS-GVO das Recht, sich bei einer Datenschutzaufsichtsbehörde eigener Wahl zu beschweren. Hierzu gehört auch die für den Verantwortlichen zuständige Datenschutzaufsichtsbehörde Bayerisches Landesamt für Datenschutzaufsicht (BayLDA) Promenade 27, 91522 Ansbach. Für die E-Mail-Kommunikation mit der Aufsichtsbehörde können Sie folgende E-Mail-Adresse nutzen: <a href="mailto:poststelle@lda.bayern.de" class="no-color">poststelle@lda.bayern.de</a>.</p>


            <p><br><strong>7. Dauer der Speicherung personenbezogener Daten/Löschung personenbezogener Daten</strong></p>

            <p>Generell löschen oder anonymisieren wir Ihre personenbezogenen Daten, sobald sie für die Zwecke, für die wir sie nach den vorstehenden Ziffern erhoben oder verwendet haben, nicht mehr erforderlich sind, es sei denn, die weitere Speicherung Ihrer personenbezogenen Daten ist aufgrund einer rechtlichen Verpflichtung erforderlich. Nähere Angaben zu den entsprechenden Löschfristen finden Sie jeweils bei der Beschreibung der einzelnen Datenverarbeitungen.</p>


            <p><br><strong>8. Weitergabe von Daten an Dritte / Empfänger der Daten</strong></p>

            <p>Die von uns erhobenen und gespeicherten personenbezogenen Daten werden von uns in keinem Fall zum Verkauf, zum Handel oder zur Ausleihe genutzt. Ihre Daten geben wir nur an Dritte weiter, sofern wir hierzu gesetzlich verpflichtet sind, bzw. zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen, zur Aufklärung einer rechtswidrigen Nutzung unserer Webseite oder Angebote oder für die Rechtsverfolgung (sofern konkrete Anhaltspunkte für ein gesetzeswidriges beziehungsweise missbräuchliches Verhalten vorliegen) erfolgen. Eine Weitergabe von Daten kann auch dann stattfinden, wenn dies der Durchsetzung von Nutzungsbedingungen oder anderer Vereinbarungen dient. Wir sind zudem gesetzlich verpflichtet, auf Anfrage bestimmten öffentlichen Stellen Auskunft zu erteilen. Dies sind Strafverfolgungsbehörden, Behörden, die bußgeldbewährte Ordnungswidrigkeiten verfolgen und die Finanzbehörden. Die Weitergabe dieser Daten erfolgt auf Grundlage unseres berechtigten Interesses an der Bekämpfung von Missbrauch, der Verfolgung von Straftaten und der Sicherung, Geltendmachung und Durchsetzung von Ansprüchen. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DS-GVO.</p>
            <p>Wir sind für das Angebot unserer Produkte und Dienstleistungen auf vertraglich verbundene Fremdunternehmen und externe Dienstleister („Auftragsverarbeiter“) angewiesen. In solchen Fällen werden personenbezogene Daten an diese Auftragsverarbeiter weitergegeben, um diesen die weitere Bearbeitung zu ermöglichen. Diese Auftragsverarbeiter werden von uns sorgfältig ausgewählt und regelmäßig überprüft, um sicherzugehen, dass Ihre Privatsphäre gewahrt bleibt. Die Auftragsverarbeiter dürfen die Daten ausschließlich zu den von uns vorgegebenen Zwecken verwenden und werden darüber hinaus von uns vertraglich verpflichtet, Ihre Daten ausschließlich gemäß dieser Datenschutzerklärung sowie den deutschen Datenschutzgesetzen zu behandeln.</p>


            <p><br><strong>9. Kontaktmöglichkeit / Datenschutzbeauftragter</strong></p>

            <p>Für eine Auskunft über Ihre personenbezogenen Daten, zur Veranlassung einer Korrektur unrichtiger Daten oder deren Sperrung bzw. Löschung sowie für weitergehende Fragen über die Verwendung Ihrer personenbezogenen Daten steht Ihnen folgende Kontaktmöglichkeit über unseren Konzerndatenschutzbeauftragten offen:</p>
            <p>Zeppelin GmbH Konzerndatenschutzbeauftragter Graf-Zeppelin-Platz 1<br>
                85748 Garching b. München Tel: +49 89 32 000-0<br>
                Fax: +49 89 32 000-482<br>
                E-Mail: <a href="mailto:datenschutz@zeppelin.com" class="no-color">datenschutz@zeppelin.com</a></p>
            <p>Bitte beachten Sie, dass eine Auskunft ausschließlich dann erteilt werden kann, wenn Sie uns Ihren vollständigen Vor- und Nachnamen, Ihre aktuelle und ggf. auch alte Anschrift, Ihr Geburtsdatum und Ihre Emailanschrift angeben. Diese Angaben dienen ausschließlich zum Abgleich und stellen somit für Sie einen Schutz dar, dass nicht unberechtigte Dritte Ihre persönlichen Daten erhalten. Wünschenswert und hilfreich, aber nicht notwendig, sind darüber hinaus auch etwaige Angebots-, Vorgangs- und/oder Vertragsnummern, die wir Ihnen mitgeteilt haben, damit wir schneller die betreffenden Daten identifizieren können.</p>

            <p><br>Stand: September 2022</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'data-protection'
    }
</script>