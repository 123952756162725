<template>
    <div class="stepper-wrapper">
        <div class="row no-gutters">
            <div class="col-12 col-md-8 col-lg-7 mx-auto">
                <div class="d-flex justify-content-between">
                    <div v-for="(step,index) in steps" :key="index"
                         class="step d-flex flex-column"
                         :class="[
                            {'step-complete': stepIsComplete(index)},
                            {'current-step arrow-indicator': stepIsCurrent(index)},
                            {'arrow-indicator reverse-arrow': completeAll && index === 1},
                            {'step-incomplete': stepIsIncomplete(index)}
                            ]">
                        <div class="outer-step font-mono d-flex align-self-center justify-content-center align-items-center">
                            <router-link :to="getRoute(step.route, index)" :class="'step-' + (index + 1)" v-if="index < currentStep">
                                <span>{{index + 1}}</span>
                            </router-link>
                            <div :class="'step-' + (index + 1)" v-else>
                                <span>{{index + 1}}</span>
                            </div>
                        </div>
                        <span class="font-size-smaller text-monospace" v-html="step.title"></span>
                    </div>
                </div>
                <span class="stepper-line"></span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Stepper',
        data() {
            return {
                steps: [
                    {
                        title: 'Ihre Gebraucht&shy;maschine',
                        route: {name: 'home'}
                    },
                    {
                        title: 'Basisdaten Ihrer Gebraucht&shy;maschine',
                        route: {name: 'condition'}
                    },
                    {
                        title: 'Ihre Kontaktdaten',
                        route: {name: 'contactform'}
                    },
                ]
            }
        },
        mounted() {
          console.log(this.currentStep)
        },
        props: {
            // steps: {
            //     type: Array,
            //     required: true
            // },
            currentStep: {
                type: Number,
                required: false,
                default: 0
            },
            completeAll: {
                type: Boolean,
                required: false,
                default: false
            },
            incompleteAll: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        methods: {
            stepIsComplete(i) {
                return (i < this.currentStep) || this.completeAll;
            },
            stepIsIncomplete(i) {
                return ((i > this.currentStep) || this.incompleteAll) && !this.completeAll;
            },
            stepIsCurrent(i) {
                return i === this.currentStep && !this.incompleteAll && !this.completeAll;
            },
            getRoute(target, i) {
                if (i < this.currentStep) {
                    return target;
                }
                return {};
            }
        }
    }
</script>

<style lang="scss">
    $radius-outer: 54px;
    $radius-offset-inner: 10px;
    $radius-inner: 40px;

    .stepper-wrapper {
        position: relative;
        background-color: $primary;

        .outer-step {
            width: $radius-outer;
            height: $radius-outer;
            margin: $radius-offset-inner;
            background-color: transparent;
            border-radius: $radius-outer / 2;
        }

        .step {
            position: relative;
            text-align: center;
            padding: $radius-offset-inner * 2 $radius-offset-inner * 2;
            z-index: 3;

            &:first-child {
                &:before {
                    right: 50%;
                    left: 0;
                }
            }

            &:last-child {
                &:before {
                    right: 0;
                    left: 50%;
                }
            }

            &:before {
                z-index: -1;
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                background-color: $primary;
            }

            @include media-breakpoint-down(md) {
                padding: $radius-offset-inner $radius-offset-inner $radius-offset-inner * 2;
            }

            &.step-complete {
                div[class*='step-'], a[class*='step-'] {
                    color: $primary;
                }
            }

            &.step-incomplete {
                div[class*='step-'], a[class*='step-'] {
                    border: 1px solid $secondary;
                    background-color: $primary;
                    color: $secondary;
                }
            }

            div[class*='step-'], a[class*='step-'] {
                position: relative;
                display: inline-block;
                width: $radius-inner;
                height: $radius-inner;
                background-color: $secondary;
                color: $white;
                border-radius: $radius-inner / 2;
                font-size: $font-size-base;
                font-weight: bold;

                & span {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    line-height: $radius-inner;
                    font-size: $radius-inner / 2;
                    height: $radius-inner;
                }
            }
        }

        .current-step {
            .outer-step {
                background-color: rgba($secondary, 0.2);
            }
        }

        .stepper-line {
            position: absolute;
            width: 100%;
            height: 1px;
            border-bottom: 1px solid $secondary;
            top: 57px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;

            @include media-breakpoint-down(md) {
                top: 47px;
            }
        }
    }
</style>